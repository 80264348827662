export class AppConfig {
    // This is a temporary change and the amount handling will be
    // fixed in https://oddr.atlassian.net/browse/CBH-2373
    static currencySymbol = '$';
    static locale = 'en-US';
    static currency = 'USD';

    // static readonly dateTimeFormat: string = 'MM-DD-YYYY h:mm a';
    static readonly dateFormat: string = 'MM-DD-YYYY';
    static readonly timeFormat: string = 'h:mm a';
    static readonly dayDateMonthNameFormat: string = 'dddd, MMM DD, YYYY';
    static readonly dateMonthNameFormat: string = 'MMM DD, YYYY';
    static readonly dateTimeMonthNameFormat: string = 'MMM DD, YYYY h:mm a';
    static readonly dateTimeSecondsMonthNameFormat: string = 'MMM DD, YYYY h:mm:ss a';
    static readonly monthYearFormat: string = 'MMM YYYY';

    //This is to be used when there are dayjs operations.
    //Some browsers do not support non ISO standard dates.
    //i.e dayjs(non-iso-date).format('formatString') will not work
    static readonly isoDateFormat: string = 'YYYY-MM-DD';
}
