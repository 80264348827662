import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import { Suspense } from 'react';
import NavHeader from '../header/NavHeader';
import Footer from './Footer';
import EmptyPageLoader from '../../common/components/EmptyPageLoader';

const { Header, Content } = Layout;

// This is to be used as a simple wrapper layout
export default function SimpleLayout() {
    return (
        <Layout>
            <Header style={{ padding: 0 }}>
                <NavHeader simpleHeader />
            </Header>
            <Layout>
                <Content className='layout__content--scrollable'>
                    <div className='site-container'>
                        <Suspense fallback={<EmptyPageLoader label='' />}>
                            <Outlet />
                        </Suspense>
                    </div>
                </Content>
            </Layout>
            <Footer />
        </Layout>
    );
}
