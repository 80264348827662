import { QUERYABLE_REMINDER_STATUS } from '../../models/reminder';

export class ReminderListURLSearchParamBuilder {
    urlSearchParams: URLSearchParams;
    constructor(params?: URLSearchParams) {
        this.urlSearchParams = params ?? new URLSearchParams();
    }

    addClientIds(clientIds: string[]) {
        clientIds.forEach((clientId) => {
            this.urlSearchParams.append('clientIds', clientId);
        });
        return this;
    }

    addMatterIds(matterIds: string[]) {
        matterIds.forEach((matterId) => {
            this.urlSearchParams.append('matterIds', matterId);
        });
        return this;
    }

    addTagNames(tagNames: string[]) {
        tagNames.forEach((name) => {
            this.urlSearchParams.append('tagNames', name);
        });
        return this;
    }

    addNoteOwnerTypes(noteTypes: string[]) {
        noteTypes.forEach((type) => {
            this.urlSearchParams.append('noteOwnerTypes', type);
        });
        return this;
    }

    addBillingTimekeeperIds(billingTimekeeperIds: string[]) {
        billingTimekeeperIds.forEach((billingTimekeeperId) => {
            this.urlSearchParams.append('timekeeperIds', billingTimekeeperId);
        });
        return this;
    }

    addAssigneeIds(assigneeIds: string[]) {
        assigneeIds.forEach((assigneeId) => {
            this.urlSearchParams.append('assigneeIds', assigneeId);
        });
        return this;
    }

    addExcludeAutoReminders(exclude: boolean) {
        this.urlSearchParams.append('excludeAutoReminders', String(exclude));
        return this;
    }

    addMyReminders(myReminders: boolean) {
        this.urlSearchParams.append('myReminders', String(myReminders));
        return this;
    }

    addStatus(reminderStatus: QUERYABLE_REMINDER_STATUS) {
        this.urlSearchParams.append('statuses', reminderStatus);
        return this;
    }
}
