import apiClient from '../../shared/api/apiClient';
import { MatterIdentifier } from '../models/matter';
import { AssignedNoteTag, Note, NoteAttachment } from '../models/note';
import { Reminder } from '../models/reminder';

const Notes = {
    BASE_URL: '/notes',
    getNoteIdentifierUrl: (noteId: string) => `${Notes.BASE_URL}/${noteId}`,
    getNoteAttachmentsBaseUrl: (noteId: string) =>
        `${Notes.getNoteIdentifierUrl(noteId)}/attachments`,
    getNoteAttachmentIdentifierUrl: (noteId: string, attachmentId: string) =>
        `${Notes.getNoteAttachmentsBaseUrl(noteId)}/${attachmentId}`,
    getNoteSharedToMattersBaseUrl: (noteId: string) =>
        `${Notes.getNoteIdentifierUrl(noteId)}/sharedToMatters`,
    getNote: (noteId: string) => apiClient.get<Note>(Notes.getNoteIdentifierUrl(noteId)),
    deleteNote: (noteId: string) => apiClient.delete<Note>(Notes.getNoteIdentifierUrl(noteId)),
    updateNote: (noteId: string, content: string) =>
        apiClient.put<Note>(Notes.getNoteIdentifierUrl(noteId), { content }),
    createReminder: (noteId: string, remindOn: string) =>
        apiClient.post<Reminder>(`${Notes.getNoteIdentifierUrl(noteId)}/reminder`, { remindOn }),
    getAttachments: (noteId: string) =>
        apiClient.get<NoteAttachment[]>(Notes.getNoteAttachmentsBaseUrl(noteId)),
    createAttachment: (noteId: string, formData: FormData) =>
        apiClient.post<NoteAttachment>(Notes.getNoteAttachmentsBaseUrl(noteId), formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }),
    deleteAttachment: (noteId: string, attachmentId: string) =>
        apiClient.delete(Notes.getNoteAttachmentIdentifierUrl(noteId, attachmentId)),
    getAttachmentContent: (noteId: string, attachmentId: string) =>
        apiClient.get<Blob>(
            `${Notes.getNoteAttachmentIdentifierUrl(noteId, attachmentId)}/content`,
            {
                responseType: 'blob'
            }
        ),
    getSharedToMatters: (noteId: string) =>
        apiClient.get<MatterIdentifier[]>(Notes.getNoteSharedToMattersBaseUrl(noteId)),
    shareMatterNote: (noteId: string, sharedToMatterIds: string[]) =>
        apiClient.put<void>(Notes.getNoteSharedToMattersBaseUrl(noteId), {
            sharedToMatterIds
        }),
    pinNote: (noteId: string) => apiClient.post<void>(`${Notes.getNoteIdentifierUrl(noteId)}/_pin`),
    unPinNote: (noteId: string) =>
        apiClient.post<void>(`${Notes.getNoteIdentifierUrl(noteId)}/_unpin`),
    getAssignedNoteTags: (noteId: string) =>
        apiClient.get<AssignedNoteTag[]>(`${Notes.getNoteIdentifierUrl(noteId)}/tags`),
    updateAssignedNoteTags: (noteId: string, tagKeys: string[]) =>
        apiClient.put<void>(`${Notes.getNoteIdentifierUrl(noteId)}/tags`, tagKeys)
};

export default Notes;
