import apiClient from '../../shared/api/apiClient';
import { PaginatedResult } from '../models/list/pagination';
import { Payment, ProcessingAccount } from '../models/payment';

const Payments = {
    BASE_URL: '/payments',
    getPayments: (params: URLSearchParams) =>
        apiClient.get<PaginatedResult<Payment>>(Payments.BASE_URL, { params }),
    getProcessingAccounts: () =>
        apiClient.get<ProcessingAccount[]>(`${Payments.BASE_URL}/processingaccounts`)
};

export default Payments;
