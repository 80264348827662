import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agent';
import { mapStateToStatus, StatementURLSearchParamBuilder } from '../common/utils/statement';
import { URLParams } from '../common/utils/urlParams';
import { ENTITY_TYPE } from '../models/entity';
import { PaginatedResult, PaginationParams } from '../models/list/pagination';
import { SortingParams } from '../models/list/sorting';
import { ClientOrMatterStatement, STATEMENT_STATE } from '../models/statement';
import { store } from './store';

export enum PAGE_KEY {
    PROFILE_STATEMENTS_SENT = 'profileStatement!sent',
    PROFILE_STATEMENTS_NOT_SENT = 'profileStatement!notSent'
}

type LoadStatementsParams = {
    entityType: ENTITY_TYPE.CLIENT | ENTITY_TYPE.MATTER;
    statementState: STATEMENT_STATE;
} & (
    | { entityType: ENTITY_TYPE.CLIENT; clientId: string }
    | { entityType: ENTITY_TYPE.MATTER; clientId: string; matterId: string }
);

export default class ProfileStatementsMenuStore {
    statements: PaginatedResult<ClientOrMatterStatement> | null = null;
    loading = false;

    constructor() {
        makeAutoObservable(this);
    }

    urlParams = new URLParams([
        PAGE_KEY.PROFILE_STATEMENTS_NOT_SENT,
        PAGE_KEY.PROFILE_STATEMENTS_SENT
    ]);

    getStatementStatePageKey = (type: STATEMENT_STATE) => `profileStatement!${type}`;

    getStatementListUrlParams = (type: STATEMENT_STATE) => {
        const params = new URLSearchParams();
        const pageKey = this.getStatementStatePageKey(type);

        const paginationParams = this.urlParams.getPaginationParams(pageKey);
        paginationParams.pageSize = store.userStore.pageSize;
        params.append('pageNumber', paginationParams!.pageNumber.toString());
        params.append('pageSize', paginationParams!.pageSize.toString());

        const sortingParams = this.urlParams.getSortingParams(pageKey);
        params.append('orderBy', sortingParams.sortExpression);

        return params;
    };

    getPaginationParams = (pageKey: PAGE_KEY) => {
        return this.urlParams.getPaginationParams(pageKey);
    };

    getSortingParams = (pageKey: PAGE_KEY) => {
        return this.urlParams.getSortingParams(pageKey);
    };

    getSearchParams = (pageKey: PAGE_KEY) => {
        return this.urlParams.getSearchParams(pageKey);
    };

    setPaginationParams = (pageKey: PAGE_KEY, paginationParams: PaginationParams) => {
        this.urlParams.setPaginationParams(pageKey, paginationParams);
    };

    setSortingParams = (pageKey: PAGE_KEY, sortingParams: SortingParams) => {
        this.urlParams.setSortingParams(pageKey, sortingParams);
        this.urlParams.setPaginationParams(pageKey, new PaginationParams());
    };

    loadStatements = async (params: LoadStatementsParams) => {
        this.loading = true;
        const { entityType, clientId, statementState } = params;

        const paramsBuilder = new StatementURLSearchParamBuilder(
            statementState as STATEMENT_STATE,
            this.getStatementListUrlParams(statementState)
        ).addStatuses(mapStateToStatus(statementState as STATEMENT_STATE));

        let statements_: PaginatedResult<ClientOrMatterStatement> | undefined;
        try {
            switch (entityType) {
                case ENTITY_TYPE.CLIENT: {
                    const statements = await agent.Clients.getClientStatements(
                        clientId,
                        paramsBuilder.urlSearchParams
                    );
                    statements_ = statements;
                    break;
                }
                case ENTITY_TYPE.MATTER: {
                    const statements = await agent.Matters.getMatterStatements(
                        clientId,
                        params.matterId,
                        paramsBuilder.urlSearchParams
                    );
                    statements_ = statements;
                    break;
                }
            }

            // Reset the page number to total pages and
            // refetch the data if it exceeds total pages
            const totalPages = statements_.paginationInfo.totalPages || 1;
            const pageKey = this.getStatementStatePageKey(params.statementState) as PAGE_KEY;
            if (this.getPaginationParams(pageKey).pageNumber > totalPages) {
                this.setPaginationParams(pageKey, new PaginationParams(totalPages));
                await this.loadStatements(params);
                return;
            }

            runInAction(() => {
                if (statements_) {
                    this.statements = statements_;
                }
            });
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    };
}
