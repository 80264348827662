import { observer } from 'mobx-react-lite';
import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import MainLayout from '../layout/appLayouts/MainLayout';
import SimpleLayout from '../layout/appLayouts/SimpleLayout';
import { useStore } from '../stores/store';
import RoutePaths from './RoutePaths';
import AuthenticatedRoute from './conditionalRoutes/AuthenticatedRoute';
import AuthorizedRoute from './conditionalRoutes/AuthorizedRoute';
import UnAuthenticatedOnlyRoute from './conditionalRoutes/UnAuthenticatedOnlyRoute';
const ApproveWriteOff = lazy(() => import('../../features/invoices/writeOff/ApproveWriteOff'));
const Clients = lazy(() => import('../../features/clients/Clients'));
const InvitedClientList = lazy(
    () => import('../../features/admin/clientPortal/invitedClientList/InvitedClientList')
);
const ClientProfile = lazy(() => import('../../features/clients/clientProfile/ClientProfile'));
const MatterProfile = lazy(() => import('../../features/matters/matterProfile/MatterProfile'));
const Dashboard = lazy(() => import('../../features/dashboard/Dashboard'));
const InvoiceDashboard = lazy(() => import('../../features/invoices/dashboard/InvoiceDashboard'));
const InvoiceDetails = lazy(() => import('../../features/invoices/details/InvoiceDetails'));
const NeedsAttention = lazy(() => import('../../features/needsAttention/NeedsAttention'));
const LandingPage = lazy(() => import('../../features/LandingPage'));
const NotFound = lazy(() => import('../../features/errors/NotFound'));
const Reminders = lazy(() => import('../../features/reminders/Reminders'));
const SendBackWriteOff = lazy(() => import('../../features/invoices/writeOff/SendBackWriteOff'));
const ServerError = lazy(() => import('../../features/errors/ServerError'));
const SSOCallback = lazy(() => import('../../features/login/sso/SSOCallback'));
const Payments = lazy(() => import('../../features/payments/PaymentList'));
const Settings = lazy(() => import('../../features/admin/settings/components/Settings'));
const UserList = lazy(() => import('../../features/admin/users/UserList'));
const Matters = lazy(() => import('../../features/matters/Matters'));
const StatementDashboard = lazy(
    () => import('../../features/statements/dashboard/StatementDashboard')
);
const StatementDetails = lazy(
    () => import('../../features/statements/statementDetails/StatementDetails')
);

export default observer(function AppRoutes() {
    const {
        userStore: { user },
        uiStore: { uiPreferences },
        tenantStore: { tenant }
    } = useStore();
    const capabilities = user?.capabilities;

    return (
        <Routes>
            {/* Routes that requires the side bar */}
            <Route
                path={RoutePaths.DEFAULT_ROUTE}
                element={
                    <AuthenticatedRoute
                        idPConfigured={!!tenant?.idPConfigured}
                        isAuthenticated={!!user}
                    >
                        <MainLayout />
                    </AuthenticatedRoute>
                }
            >
                <Route
                    path={RoutePaths.DEFAULT_ROUTE}
                    element={
                        <AuthorizedRoute
                            isAuthorized={!!capabilities?.viewDashboard}
                            redirectTo={uiPreferences.homePage}
                        >
                            <Dashboard />
                        </AuthorizedRoute>
                    }
                />

                <Route path={RoutePaths.INVOICES}>
                    <Route path={RoutePaths.INVOICE_LIST} element={<InvoiceDashboard />} />
                    <Route
                        path={RoutePaths.INVOICE_DETAILS}
                        element={<InvoiceDetails isTimekeeper={user?.isTimekeeper} />}
                    />
                </Route>

                <Route path={RoutePaths.CLIENTS}>
                    <Route path={RoutePaths.MY_CLIENT_LIST} element={<Clients showOnlyMine />} />
                    <Route path={RoutePaths.ALL_CLIENT_LIST} element={<Clients />} />
                    <Route path={RoutePaths.CLIENT_PROFILE}>
                        <Route path='' element={<ClientProfile />} />
                        <Route path={RoutePaths.MATTER_PROFILE} element={<MatterProfile />} />
                    </Route>
                </Route>

                <Route
                    path={RoutePaths.MATTERS}
                    element={<AuthorizedRoute isAuthorized={!!capabilities?.viewMatters} />}
                >
                    <Route path={RoutePaths.MY_MATTER_LIST} element={<Matters showOnlyMine />} />
                    <Route path={RoutePaths.ALL_MATTER_LIST} element={<Matters />} />
                </Route>
                <Route
                    path={RoutePaths.PAYMENTS}
                    element={
                        <AuthorizedRoute isAuthorized={!!capabilities?.canViewOnlinePayments} />
                    }
                >
                    <Route path={RoutePaths.PAYMENTS} element={<Payments />} />
                </Route>
                <Route
                    path={RoutePaths.STATEMENTS}
                    element={<AuthorizedRoute isAuthorized={!!capabilities?.viewStatements} />}
                >
                    <Route path={RoutePaths.STATEMENTS_LIST} element={<StatementDashboard />} />

                    <Route path={RoutePaths.STATEMENT_DETAILS} element={<StatementDetails />} />
                </Route>

                <Route path={RoutePaths.NEEDS_ATTENTION} element={<NeedsAttention />} />
                <Route
                    path={RoutePaths.REMINDERS_LIST}
                    element={
                        <AuthorizedRoute isAuthorized={!!capabilities?.viewReminders}>
                            <Reminders />
                        </AuthorizedRoute>
                    }
                />

                <Route
                    path={RoutePaths.ADMIN}
                    element={
                        <AuthorizedRoute isAuthorized={!!capabilities?.canManageAdministration} />
                    }
                >
                    <Route path={RoutePaths.ADMIN_CLIENT_PORTAL} element={<InvitedClientList />} />
                    <Route path={RoutePaths.ADMIN_USERS} element={<UserList />} />
                    <Route path={RoutePaths.ADMIN_SETTINGS} element={<Settings />} />
                </Route>

                <Route path={RoutePaths.SERVER_ERROR} element={<ServerError />} />
            </Route>

            {/* Routes that do not require side bar and search */}
            <Route
                path={RoutePaths.DEFAULT_ROUTE}
                element={
                    <AuthenticatedRoute
                        isAuthenticated={!!user}
                        idPConfigured={!!tenant?.idPConfigured}
                    >
                        <SimpleLayout />
                    </AuthenticatedRoute>
                }
            >
                <Route path={RoutePaths.INVOICES}>
                    <Route
                        path={RoutePaths.INVOICE_WRITEOFF_APPROVE}
                        element={<ApproveWriteOff />}
                    />
                    <Route
                        path={RoutePaths.INVOICE_WRITEOFF_COMPLETE}
                        element={<SendBackWriteOff />}
                    />
                </Route>
            </Route>

            {/* Routes that can be accessed only when not authenticated */}
            <Route
                path={RoutePaths.DEFAULT_ROUTE}
                element={<UnAuthenticatedOnlyRoute isAuthenticated={!!user} />}
            >
                <Route path={RoutePaths.LOGIN} element={<LandingPage />} />
                <Route path={RoutePaths.SSO_CALLBACK} element={<SSOCallback />} />
            </Route>

            <Route path='*' element={<NotFound />} />
        </Routes>
    );
});
