import { Layout } from 'antd';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import NavHeader from '../header/NavHeader';
import SideBar from '../sideBar/SideBar';
import EmptyPageLoader from '../../common/components/EmptyPageLoader';
import Footer from './Footer';
import './Layout.css';
const { Content, Header } = Layout;

// This layout is used for Logged in experience
export default function MainLayout() {
    return (
        <Layout>
            <Header style={{ padding: 0 }}>
                <NavHeader />
            </Header>
            <Layout>
                <SideBar />
                <Content className='layout__content--scrollable'>
                    <div className='site-container'>
                        <Suspense fallback={<EmptyPageLoader label='' />}>
                            <Outlet />
                        </Suspense>
                    </div>
                </Content>
            </Layout>
            <Footer />
        </Layout>
    );
}
