import { ClientIdentifier } from './client';
import { InvoiceIdentifier } from './invoice';
import { MatterIdentifier } from './matter';
import { StatementIdentifier } from './statement';

export type Transaction = {
    transactionId: string;
    convenienceFee: number;
    referenceNumber: string;
    paymentMadeOn: Date;
    paymentMethodDescription: string;
    processOn: Date;
    paymentMethodType: PaymentMethodType;
    paymentMethodTypeName: string;
};

export type Payment = {
    amount: number;
    invoice: InvoiceIdentifier | null;
    client: ClientIdentifier;
    matter: MatterIdentifier | null;
    createdOn: Date;
    error: string | null;
    id: string;
    note: string | null;
    scheduledOn: Date | null;
    statement: StatementIdentifier | null;
    transaction: Transaction;
};

export type ProcessingAccount = {
    id: string;
    name: string;
    default: boolean;
};

export type PaymentLink = {
    note: string | null;
    paymentAmount: number | null;
    paymentLink: string;
    processingAccountId: string | null;
    expiresOn: Date;
};

export enum PAYMENT_TYPE {
    NONE = 'None',
    CLIENT_PAY = 'ClientPay',
    PAYLOAD = 'Payload'
}

export enum PaymentFilter {
    CLIENTS = 'clientIds',
    INCLUDE_RELATED_CLIENTS = 'includeRelatedClients'
}

export enum PaymentMethodType {
    BANK_ACCOUNT = 'BankAccount',
    CREDIT_CARD = 'CreditCard',
    DEBIT_CARD = 'DebitCard'
}

export interface PaymentFilters {
    [PaymentFilter.CLIENTS]: string[];
    [PaymentFilter.INCLUDE_RELATED_CLIENTS]: string[];
}
