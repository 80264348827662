import { ReactElement, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { AutoComplete, Flex, Input, InputRef, Space, Spin, Typography } from 'antd';
import {
    AuditOutlined,
    CloseCircleFilled,
    FileTextOutlined,
    LoadingOutlined,
    SearchOutlined,
    UserOutlined
} from '@ant-design/icons';
import { debounce } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

import { DEBOUNCE_DEFAULT_DELAY_IN_MS } from '../common/constants/app';
import { trim } from '../common/utils/string';
import { ClientLookup } from '../models/client';
import { InvoiceLookup } from '../models/invoice';
import { MatterLookup } from '../models/matter';
import { useStore } from '../stores/store';

const { Text } = Typography;

const MAX_RESULTS = 10;

export type OptionItem = {
    label: ReactNode;
    options: {
        label: ReactNode;
        key: string;
        data: ClientLookup | InvoiceLookup | MatterLookup;
    }[];
};

type NotFoundContentProps = { fetching: boolean; searchTerm: string };

export default observer(function GlobalSearchComponent() {
    const {
        commonStore: { globalQuerylookup }
    } = useStore();

    const [options, setOptions] = useState<OptionItem[]>([]);
    const [fetching, setFetching] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [isInputSizeChanged, setIsInputSizeChanged] = useState(false);

    const searchBoxRef = useRef<InputRef>(null);
    const inputSizeChangeTimeoutRef = useRef<NodeJS.Timeout>();

    const navigate = useNavigate();

    useEffect(() => {
        // This is a shortcut to open search bar by clicking "/".
        const keyupListener = (e: KeyboardEvent) => {
            const target = e.target as Element;
            const tagName = target.tagName.toLowerCase();

            if (e.key !== '/' || e.ctrlKey || e.metaKey) {
                return;
            }
            // Not to work on other input places
            if (
                tagName === 'input' ||
                tagName === 'textarea' ||
                tagName === 'select' ||
                tagName === 'button' ||
                (tagName === 'div' && target.className === 'ql-editor') // RTF input
            ) {
                return;
            }
            e.preventDefault();
            searchBoxRef.current?.focus({ cursor: 'end' });
        };

        document.addEventListener('keyup', keyupListener);

        return () => {
            document.removeEventListener('keyup', keyupListener);
        };
    }, []);

    const handleBlur = () => {
        // Run the input blur handler only if no manual blur is scheduled.
        if (!inputSizeChangeTimeoutRef.current) {
            setIsInputSizeChanged(false);
        }
    };

    const handleFocus = () => {
        // Since handleDropdownVisibleChange runs before focus, it only handles cases where the browser auto-focuses, such as when switching between screens or opening the browser console.
        if (!isInputSizeChanged) {
            setIsInputSizeChanged(true);

            clearTimeout(inputSizeChangeTimeoutRef?.current);
            inputSizeChangeTimeoutRef.current = undefined;
        }
    };

    const renderTitle = (title: string, icon: ReactElement, totalResults: number) => (
        <Flex justify='space-between' align='center'>
            <Space>
                {icon}
                <Text style={{ color: '#595959' }}>{title}</Text>
            </Space>
            {totalResults > MAX_RESULTS && (
                <Text
                    style={{ fontSize: '12px', color: '#595959' }}
                >{`showing top ${MAX_RESULTS} ${title}`}</Text>
            )}
        </Flex>
    );

    const handleSearch = (value: string) => {
        setSearchTerm(value);

        // Avoid calling api when query is less than three characters.
        if (value.length < 3) {
            return;
        }

        loadOptions(value);
    };

    const loadOptions = useCallback(
        debounce(async (value: string) => {
            setFetching(true);

            // MAX_RESULTS + 1 will help us identify if there are more results and display
            // the max results label to end users.
            const results = await globalQuerylookup(trim(value), MAX_RESULTS + 1);

            if (!results) {
                setOptions([]);
                setFetching(false);
                return [];
            }

            const newOptions: OptionItem[] = [];

            if (results.clients.length > 0) {
                newOptions.push({
                    label: renderTitle('Clients', <UserOutlined />, results.clients.length),
                    options: results.clients.slice(0, MAX_RESULTS).map((client) => ({
                        label: `${client.clientId} - ${client.name}`,
                        value: client.clientId,
                        key: `${client.clientId} - ${client.name}`,
                        data: client
                    }))
                });
            }

            if (results.matters.length > 0) {
                newOptions.push({
                    label: renderTitle('Matters', <AuditOutlined />, results.matters.length),
                    options: results.matters.slice(0, MAX_RESULTS).map((matter) => ({
                        label: `${matter.matterId} - ${matter.name}`,
                        key: `${matter.matterId} - ${matter.clientId}`,
                        value: matter.matterId,
                        data: matter
                    }))
                });
            }

            if (results.invoices.length > 0) {
                newOptions.push({
                    label: renderTitle('Invoices', <FileTextOutlined />, results.invoices.length),
                    options: results.invoices.slice(0, MAX_RESULTS).map((invoice) => ({
                        label: `${invoice.number} - ${invoice.matterName}`,
                        key: `${invoice.invoiceId} - ${invoice.matterName}`,
                        value: invoice.invoiceId,
                        data: invoice
                    }))
                });
            }

            setOptions(newOptions);
            setFetching(false);
        }, DEBOUNCE_DEFAULT_DELAY_IN_MS),
        []
    );

    const onSelect = (data: OptionItem['options'][number]['data']) => {
        if ('invoiceId' in data) {
            const invoiceData = data as InvoiceLookup;
            navigate(`/invoices/${invoiceData.invoiceId}`);
        } else if ('matterId' in data) {
            const matterData = data as MatterLookup;
            navigate(`/clients/${matterData.clientId}/matters/${matterData.matterId}`);
        } else if ('clientId' in data) {
            const clientData = data as ClientLookup;
            navigate(`/clients/${clientData.clientId}`);
        }
    };

    const handleSearchTermClear = () => {
        setOptions([]);
    };

    const handleDropdownVisibleChange = (isOpen: boolean) => {
        if (isOpen) {
            setIsInputSizeChanged(isOpen);

            // There is a case where when we type something and then press escape, so avoid input to shrink we are clearing the timeout so as to not let it run.
            clearTimeout(inputSizeChangeTimeoutRef?.current);
            inputSizeChangeTimeoutRef.current = undefined;
        } else {
            // We're waiting for the dropdown to close, as it closes with an animation. We wait for the animation to finish before shrinking the input back to its initial size.
            inputSizeChangeTimeoutRef.current = setTimeout(() => {
                setIsInputSizeChanged(isOpen);

                inputSizeChangeTimeoutRef.current = undefined;
            }, 300);
        }
    };

    const handleSelect = (_: string, option: OptionItem['options'][number]) => {
        if (option.data) {
            onSelect(option.data);

            setSearchTerm('');

            handleDropdownVisibleChange(false);
            handleSearchTermClear();

            // Set the input blur after updating the state, so it takes effect properly.
            setTimeout(() => searchBoxRef.current?.blur(), 0);
        }
    };

    return (
        <AutoComplete
            options={options as unknown as OptionItem['options']}
            style={{ width: isInputSizeChanged ? 700 : 400 }}
            backfill
            onSearch={handleSearch}
            value={searchTerm}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onSelect={handleSelect}
            notFoundContent={
                searchTerm ? <NotFoundContent fetching={fetching} searchTerm={searchTerm} /> : null
            }
            popupMatchSelectWidth={isInputSizeChanged ? 700 : 400}
            onClear={handleSearchTermClear}
            onDropdownVisibleChange={handleDropdownVisibleChange}
            allowClear={{ clearIcon: <CloseCircleFilled /> }}
        >
            <Input
                ref={searchBoxRef}
                prefix={<SearchOutlined />}
                title="Use '/' to focus and search"
                placeholder='Search clients, matters and invoices'
            />
        </AutoComplete>
    );
});

function NotFoundContent({ fetching, searchTerm }: NotFoundContentProps) {
    if (fetching) {
        return <Spin indicator={<LoadingOutlined />} />;
    }

    if (searchTerm.length < 3) {
        return <>Search term must be at least 3 characters long</>;
    }

    return <>No results found</>;
}
