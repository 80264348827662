import apiClient from '../../shared/api/apiClient';
import { KeyName } from '../models/keyValuePair';
import { EBillingStatus } from '../models/dictionary';
import { INVOICE_STATUS } from '../models/invoice';
import { Tag } from '../../shared/models/tags';

const Dictionaries = {
    BASE_URL: '/dictionaries',
    getClientTags: () => apiClient.get<Tag[]>(`${Dictionaries.BASE_URL}/clienttags`),
    getMatterTags: () => apiClient.get<Tag[]>(`${Dictionaries.BASE_URL}/mattertags`),
    getEbillingStatuses: () =>
        apiClient.get<EBillingStatus[]>(`${Dictionaries.BASE_URL}/ebillingstatuses`),
    createSingleEbillingStatus: (body: { name: string; invoiceStatus: INVOICE_STATUS }) =>
        apiClient.post<EBillingStatus>(`${Dictionaries.BASE_URL}/ebillingstatuses`, { ...body }),
    getInvoiceWriteOffReasons: () =>
        apiClient.get<KeyName[]>(`${Dictionaries.BASE_URL}/invoicewriteoffreasons`),
    getNoteTags: () => apiClient.get<Tag[]>(`${Dictionaries.BASE_URL}/notetags`),
    getOffices: () => apiClient.get<KeyName[]>(`${Dictionaries.BASE_URL}/offices`),
    getClientStatuses: () => apiClient.get<KeyName[]>(`${Dictionaries.BASE_URL}/clientstatuses`),
    getMatterStatuses: () => apiClient.get<KeyName[]>(`${Dictionaries.BASE_URL}/matterstatuses`),
    getPracticeGroups: () => apiClient.get<KeyName[]>(`${Dictionaries.BASE_URL}/practiceGroups`)
};

export default Dictionaries;
