import apiClient from '../../shared/api/apiClient';
import { GlobalLookup } from '../models/search';

const Search = {
    BASE_URL: '/search',
    lookup: (params: URLSearchParams) =>
        apiClient.get<GlobalLookup>(`${Search.BASE_URL}/lookupentities`, { params })
};

export default Search;
