import apiClient from '../../shared/api/apiClient';

const Payors = {
    BASE_URL: '/payors',
    getPayorIdentifierUrl: (payorId: string) => `${Payors.BASE_URL}/${payorId}`,
    updateEmails: (payorId: string, emails: string[], provisional: boolean) =>
        apiClient.put(`${Payors.getPayorIdentifierUrl(payorId)}/emails`, { emails, provisional })
};

export default Payors;
