import apiClient from '../../shared/api/apiClient';
import { PaginatedResult } from '../models/list/pagination';
import { User, UserLookup, UserPatchProperties } from '../models/user';

const Users = {
    BASE_URL: '/users',
    getUserIdentifierUrl: (userId: string) => `${Users.BASE_URL}/${userId}`,
    get: (userId: string) => apiClient.get<User>(Users.getUserIdentifierUrl(userId)),
    getAll: (params: URLSearchParams) =>
        apiClient.get<PaginatedResult<User>>(Users.BASE_URL, { params }),
    patchUser: (userId: string, patchProperties: UserPatchProperties) =>
        apiClient.patch<void>(Users.getUserIdentifierUrl(userId), patchProperties),
    lookup: (params: URLSearchParams) =>
        apiClient.get<UserLookup[]>(`${Users.BASE_URL}/lookup`, { params })
};

export default Users;
