// These are list of hard coded values, should be improved
// when we introduce internationalization.
export const mapCurrencyToLocale = (currency: string) => {
    switch (currency) {
        case 'GBP':
            return 'en-GB';
        case 'EUR':
            // Just considering German now.
            // This is just for testing
            return 'de-DE';
        default:
            return 'en-US';
    }
};
