import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agent';
import { SearchSelectItem } from '../common/components/searchSelect/SearchSelect';
import { ClientLookupFilterSearchSelectItem } from '../common/filter/ClientLookupFilter';
import Filters from '../common/filter/filters';
import { downloadDocumentBlob } from '../common/utils/file';
import { StatementURLSearchParamBuilder } from '../common/utils/statement';
import { URLParams } from '../common/utils/urlParams';
import { CC_USER, CreateStatement, SendStatement } from '../models/client';
import { DocumentStatusAggregates } from '../models/invoice';
import { PaginatedResult, PaginationParams } from '../models/list/pagination';
import { SearchParams } from '../models/list/search';
import { SortingParams } from '../models/list/sorting';
import { PAGE_KEY } from '../models/pageConfigs';
import {
    Statement,
    STATEMENT_OWNER_TYPE,
    STATEMENT_STATE,
    StatementActivity,
    StatementFilter,
    StatementFilters,
    StatementProperty
} from '../models/statement';
import { STORAGE_KEY } from '../models/user';
import { store } from './store';

export type GetStatementsParams = {
    ownerType: STATEMENT_OWNER_TYPE;
} & (
    | {
          ownerType: STATEMENT_OWNER_TYPE.CLIENT;
          clientId: string;
      }
    | { ownerType: STATEMENT_OWNER_TYPE.MATTER; clientId: string; matterId: string }
);

export default class StatementStore {
    private readonly FILTER_GROUP_ID = 'StatementList';
    loading = false;
    statements?: PaginatedResult<Statement>;
    loadingStatements = false;

    statementStatusAggregates?: DocumentStatusAggregates;
    loadingStatementStatusAggregates = false;

    loadingStatementDocument = false;
    loadingStatement = false;
    loadingStatementForMonth = false;
    selectedStatement?: Statement;
    selectedStatementDocument: Blob | undefined;

    sendingStatement = false;
    creatingStatement = false;
    deletingStatement = true;

    loadingActivities = false;
    statementActivities: StatementActivity[] = [];

    statementStateFilter: STATEMENT_STATE = STATEMENT_STATE.SENT;

    clientIdsFilterDefaultSelections?: ClientLookupFilterSearchSelectItem[];
    billingTimekeepersFilterDefaultSelections?: SearchSelectItem[];
    matterIdsFilterDefaultSelections?: SearchSelectItem[];

    constructor() {
        makeAutoObservable(this);
    }

    urlParams = new URLParams([PAGE_KEY.STATEMENT_LIST]);

    sortingParams = {
        [STATEMENT_STATE.SENT]: new SortingParams(),
        [STATEMENT_STATE.NOT_SENT]: new SortingParams(),
        [STATEMENT_STATE.NEEDS_ATTENTION]: new SortingParams()
    };

    searchParams = {
        [STATEMENT_STATE.SENT]: new SearchParams(),
        [STATEMENT_STATE.NOT_SENT]: new SearchParams(),
        [STATEMENT_STATE.NEEDS_ATTENTION]: new SearchParams()
    };

    pagingParams = new PaginationParams();

    myStatements = false;
    statementFilters = new Filters<keyof StatementFilters>(
        this.FILTER_GROUP_ID,
        {
            [StatementFilter.CLIENTS]: [],
            [StatementFilter.BILLING_TIME_KEEPERS]: [],
            [StatementFilter.STATUSES]: [],
            [StatementFilter.STATEMENT_MONTHS]: [],
            [StatementFilter.NOT_SENT]: [],
            [StatementFilter.MATTERS]: [],
            [StatementFilter.CLIENT_TAG_NAMES]: [],
            [StatementFilter.MATTER_TAG_NAMES]: [],
            [StatementFilter.STATEMENT_DND]: [],
            [StatementFilter.INCLUDE_RELATED_CLIENTS]: []
        },
        () => this.resetPaginationParams()
    );

    // TODO: We should have a class which manages the URL params.
    // and all the stores can extend the class.
    getStatementListUrlParams(statementState: STATEMENT_STATE) {
        const params = new URLSearchParams();

        params.append('pageNumber', this.pagingParams.pageNumber.toString());
        this.pagingParams.pageSize = store.userStore.pageSize;
        params.append('pageSize', this.pagingParams.pageSize.toString());

        if (this.sortingParams[statementState].sortExpression) {
            params.append('orderBy', this.sortingParams[statementState].sortExpression);
        }

        if (this.searchParams[statementState].searchString) {
            params.append('queryText', this.searchParams[statementState].searchString!);
        }
        return params;
    }

    setStatementStateFilter = (statementStateFilter: STATEMENT_STATE) => {
        this.statementStateFilter = statementStateFilter;
        this.resetPaginationParams();
    };

    setMyStatementFilter = (myStatements: boolean) => {
        this.myStatements = myStatements;
        localStorage.setItem(STORAGE_KEY.MY_STATEMENTS, `${myStatements}`);
    };

    getPaginationParams = (pageKey: PAGE_KEY) => {
        return this.urlParams.getPaginationParams(pageKey);
    };

    getSortingParams = (pageKey: PAGE_KEY) => {
        return this.urlParams.getSortingParams(pageKey);
    };

    getSearchParams = (pageKey: PAGE_KEY) => {
        return this.urlParams.getSearchParams(pageKey);
    };

    resetPaginationParams = () => {
        this.setPagingParams(new PaginationParams());
    };

    setPagingParams = (pagingParams: PaginationParams) => {
        this.pagingParams = pagingParams;
    };

    setSortingParams = (sortingParams: SortingParams, statementState: STATEMENT_STATE) => {
        this.sortingParams[statementState] = sortingParams;
        this.resetPaginationParams();
    };

    setSearchParams = (searchParams: SearchParams, statementState: STATEMENT_STATE) => {
        this.searchParams[statementState] = searchParams;
        this.resetPaginationParams();
    };

    private getStatementURLSearchParamsBuilder = () => {
        const paramsBuilder = new StatementURLSearchParamBuilder(
            this.statementStateFilter,
            this.getStatementListUrlParams(this.statementStateFilter)
        );

        const selectedBillingTimekeeperIds =
            this.statementFilters.filters[StatementFilter.BILLING_TIME_KEEPERS];
        const selectedClientIds = this.statementFilters.filters[StatementFilter.CLIENTS];
        const selectedMonths = this.statementFilters.filters[StatementFilter.STATEMENT_MONTHS];
        const selectedMatterIds = this.statementFilters.filters[StatementFilter.MATTERS];
        const selectedClientTagNames =
            this.statementFilters.filters[StatementFilter.CLIENT_TAG_NAMES];
        const selectedMatterTagNames =
            this.statementFilters.filters[StatementFilter.MATTER_TAG_NAMES];
        const selectedDndFilters = this.statementFilters.filters[StatementFilter.STATEMENT_DND];

        paramsBuilder
            .addBillingTimekeeperIds(selectedBillingTimekeeperIds)
            .addClientIds(selectedClientIds)
            .addStatementMonths(selectedMonths)
            .addErrorsOnly()
            .addSent()
            .addMyStatements(
                this.statementStateFilter !== STATEMENT_STATE.NEEDS_ATTENTION && this.myStatements
            )
            .addMatterIds(selectedMatterIds)
            .addClientTagNames(selectedClientTagNames)
            .addMatterTagNames(selectedMatterTagNames)
            .addDndFilters(selectedDndFilters)
            .addIncludeRelatedClients(
                this.statementFilters.filters[StatementFilter.INCLUDE_RELATED_CLIENTS].length > 0
            );

        if (
            this.statementStateFilter !== STATEMENT_STATE.NEEDS_ATTENTION &&
            this.statementStateFilter !== STATEMENT_STATE.NOT_SENT
        ) {
            const selectedStatementStatuses =
                this.statementFilters.filters[StatementFilter.STATUSES];
            const availableStatementStatuses =
                this.statementStatusAggregates?.items.map((item) => item.key) ?? [];
            const applicableFilters = availableStatementStatuses.filter((statementStatus) =>
                selectedStatementStatuses.includes(statementStatus)
            );

            if (applicableFilters.length) {
                paramsBuilder.addStatuses(applicableFilters);
            }
        }

        return paramsBuilder;
    };

    getStatementDocument = async (statementId: string) => {
        this.loadingStatementDocument = true;
        try {
            const statementDocument = await agent.Statements.getDocument(statementId);
            return statementDocument;
        } catch (err: any) {
            console.log(err);
        } finally {
            runInAction(() => {
                this.loadingStatementDocument = false;
            });
        }
    };

    loadStatements = async () => {
        this.loadingStatements = true;
        try {
            const builder = this.getStatementURLSearchParamsBuilder();
            const statements = await agent.Statements.list(builder.urlSearchParams);

            // Reset the page number to total pages and
            // refetch the data if it exceeds total pages
            const totalPages = statements.paginationInfo.totalPages || 1;
            if (this.pagingParams.pageNumber > totalPages) {
                this.setPagingParams(new PaginationParams(totalPages));
                await this.loadStatements();
                return;
            }
            runInAction(() => {
                this.statements = statements;
                this.loadingStatements = false;
            });
            return statements;
        } catch (error: any) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.loadingStatements = false;
            });
        }
    };

    loadStatementStatusAggregratesAndStatements = async () => {
        await this.getStatementAggregates();
        this.loadStatements();
    };

    loadStatement = async (
        statementId: string,
        showLoader = true,
        statementProperties?: StatementProperty[]
    ) => {
        try {
            if (showLoader) {
                this.loadingStatement = true;
            }
            const params = new URLSearchParams();
            statementProperties?.forEach((property) => params.append('properties', property));

            const statement = await agent.Statements.get(statementId, params);

            const statementDocument = statement.statementDocumentName
                ? await agent.Statements.getDocument(statementId)
                : null;

            runInAction(() => {
                this.selectedStatement = statement;
                if (statementDocument) {
                    this.selectedStatementDocument = statementDocument;
                }
                this.statementActivities = statement.activities;
            });
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => (this.loadingStatement = false));
        }
        return {
            statement: this.selectedStatement,
            statementDocument: this.selectedStatementDocument
        };
    };

    getStatementForMonth = async (clientId: string, statementMonth: string, matterId?: string) => {
        this.loadingStatementForMonth = true;
        const urlParams = new URLSearchParams();
        if (matterId) {
            urlParams.append('matterId', matterId);
        }
        try {
            const statement = await agent.Statements.getStatementForMonth(
                clientId,
                statementMonth,
                urlParams
            );
            return statement;
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => (this.loadingStatementForMonth = false));
        }
    };

    downloadStatementDocument = async (statementId: string, documentName: string) => {
        try {
            const document = await agent.Statements.getDocument(statementId);
            downloadDocumentBlob(document, documentName);
        } catch (error: any) {
            console.log(error);
        }
    };

    shareStatement = async (
        number: string,
        ccUsers: CC_USER[] | null,
        emailRecipients: string[] | null,
        emailMessage: string | null,
        useCustomEmailMessage: boolean
    ) => {
        try {
            this.loading = true;
            await agent.Statements.share(number, {
                ccCurrentUser: !!ccUsers?.includes(CC_USER.CURRENT_USER),
                ccBillingTimekeeper: !!ccUsers?.includes(CC_USER.BILLING_TIMEKEEPER),
                ccCollectionTimekeeper: !!ccUsers?.includes(CC_USER.COLLECTION_TIMEKEEPER),
                emailRecipients,
                emailMessage,
                useCustomEmailMessage
            });
            return true;
        } catch (error) {
            console.log(error);
            return false;
        } finally {
            runInAction(() => (this.loading = false));
        }
    };

    markStatementAsSent = async (number: string, sentOn: string | null) => {
        try {
            this.loading = true;
            await agent.Statements.markAsSent(number, sentOn);
            return true;
        } catch (error) {
            console.log(error);
            return false;
        } finally {
            runInAction(() => (this.loading = false));
        }
    };

    createStatement = async (clientId: string, statement: CreateStatement) => {
        this.creatingStatement = true;
        const formData = new FormData();
        formData.append('clientId', clientId);
        formData.append('statementMonth', statement.statementMonth);

        if (statement.matterId) {
            formData.append('matterId', statement.matterId);
        }

        if (statement.statementDocument) {
            formData.append('document', statement.statementDocument);
        }
        if (statement.amount !== null) {
            formData.append('amount', statement.amount.toString());
        }
        if (statement.foreignCurrencyAmount !== null) {
            formData.append('foreignCurrency.Amount', statement.foreignCurrencyAmount.toString());
        }

        try {
            await agent.Statements.create(formData);
            return true;
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => {
                this.creatingStatement = false;
            });
        }
    };

    sendStatement = async (clientId: string, statement: SendStatement) => {
        this.sendingStatement = true;
        const formData = new FormData();
        formData.append('ClientId', clientId);
        formData.append('statementMonth', statement.statementMonth);
        if (statement.statementId) {
            formData.append('statementId', statement.statementId);
        }
        if (statement.matterId) {
            formData.append('matterId', statement.matterId);
        }
        if (statement.statementDocument) {
            formData.append('document', statement.statementDocument);
        }
        if (statement.amount !== null) {
            formData.append('amount', statement.amount.toString());
        }
        if (statement.foreignCurrencyAmount !== null) {
            formData.append('foreignCurrency.Amount', statement.foreignCurrencyAmount.toString());
        }

        if (statement.emailMessage?.length) {
            formData.append('emailMessage', statement.emailMessage);
        }
        formData.append('useCustomEmailMessage', String(statement.useCustomEmailMessage));
        if (statement.emailRecipients) {
            statement.emailRecipients.forEach((recipient) => {
                formData.append('emailRecipients', recipient);
            });
        }
        if (statement.ccUsers?.includes(CC_USER.CURRENT_USER)) {
            formData.append(CC_USER.CURRENT_USER, 'true');
        }
        if (statement.ccUsers?.includes(CC_USER.BILLING_TIMEKEEPER)) {
            formData.append(CC_USER.BILLING_TIMEKEEPER, 'true');
        }
        if (statement.ccUsers?.includes(CC_USER.COLLECTION_TIMEKEEPER)) {
            formData.append(CC_USER.COLLECTION_TIMEKEEPER, 'true');
        }
        try {
            await agent.Statements.sendStatement(formData);
            return true;
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => {
                this.sendingStatement = false;
            });
        }
    };

    bulkSendStatements = async (
        statementIds: string[],
        markAsSent: boolean,
        sentOn: string | null,
        ccUsers: CC_USER[],
        emailMessage: string | null,
        useCustomEmailMessage: boolean
    ) => {
        try {
            this.loading = true;
            await agent.Statements.sendStatements({
                statementIds,
                setStatusOnly: markAsSent,
                sentOn: sentOn,
                ccCurrentUser: ccUsers.includes(CC_USER.CURRENT_USER),
                ccBillingTimekeeper: ccUsers.includes(CC_USER.BILLING_TIMEKEEPER),
                ccCollectionTimekeeper: ccUsers.includes(CC_USER.COLLECTION_TIMEKEEPER),
                emailMessage,
                useCustomEmailMessage
            });
            return true;
        } catch (error) {
            console.error(error);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    };

    shareStatements = async (
        statementIds: string[],
        ccUsers: CC_USER[],
        emailMessage: string | null,
        useCustomEmailMessage: boolean
    ) => {
        this.loading = true;
        try {
            await agent.Statements.shareStatements({
                statementIds,
                ccCurrentUser: ccUsers.includes(CC_USER.CURRENT_USER),
                ccBillingTimekeeper: ccUsers.includes(CC_USER.BILLING_TIMEKEEPER),
                ccCollectionTimekeeper: ccUsers.includes(CC_USER.COLLECTION_TIMEKEEPER),
                emailMessage,
                useCustomEmailMessage
            });
            return true;
        } catch (error) {
            console.error(error);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    };

    clearError = async (
        statementIds: string[],
        markAsSent: boolean | null,
        sentOn: string | null
    ) => {
        this.loading = true;
        try {
            await agent.Statements.clearError(statementIds, markAsSent, sentOn);
            return true;
        } catch (error) {
            console.log(error);
            return false;
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    };

    getStatementActivities = async (statementId: string) => {
        this.loadingActivities = true;
        try {
            const data = await agent.Statements.getStatementActivities(statementId);
            runInAction(() => {
                this.statementActivities = data;
            });
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.loadingActivities = false;
            });
        }
    };

    getStatementAggregates = async () => {
        this.loadingStatementStatusAggregates = true;
        try {
            const aggregates = await agent.Statements.getAggregates();
            runInAction(() => {
                this.statementStatusAggregates = aggregates;
            });
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.loadingStatementStatusAggregates = false;
            });
        }
    };

    deleteStatement = async (statementId: string) => {
        this.deletingStatement = true;
        try {
            await agent.Statements.deleteStatement(statementId);
            return true;
        } catch (err) {
            console.log(err);
            return false;
        } finally {
            runInAction(() => {
                this.deletingStatement = false;
            });
        }
    };

    updateClientIdsFilterDefaultSelections = (selections: ClientLookupFilterSearchSelectItem[]) => {
        runInAction(() => {
            this.clientIdsFilterDefaultSelections = selections;
        });
    };

    updateBillingTimekeepersFilterDefaultSelections = (selections: SearchSelectItem[]) => {
        this.billingTimekeepersFilterDefaultSelections = selections;
    };

    updateMatterIdsFilterDefaultSelections = (selections: SearchSelectItem[]) => {
        runInAction(() => {
            this.matterIdsFilterDefaultSelections = selections;
        });
    };
}
