import apiClient from '../../shared/api/apiClient';
import { EmailTemplate } from '../models/template';

const EmailTemplateValues = {
    BASE_URL: '/emailtemplatevalues',
    getEmailTemplates: (params: URLSearchParams) =>
        apiClient.get<EmailTemplate[]>(EmailTemplateValues.BASE_URL, { params })
};

export default EmailTemplateValues;
