import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agent';
import { mapCurrencyToLocale } from '../common/utils/locale';
import { AppConfig } from '../models/config/appConfig';
import { Tenant } from '../models/tenant';

export default class TenantStore {
    tenant: Tenant | undefined;

    constructor() {
        makeAutoObservable(this);
    }

    loadTenant = async () => {
        try {
            const tenant = await agent.Tenants.get();
            runInAction(() => {
                this.tenant = tenant;
                AppConfig.currency = tenant.currency.code;
                AppConfig.currencySymbol = tenant.currency.symbol;
                AppConfig.locale = mapCurrencyToLocale(tenant.currency.code);
            });
        } catch (error) {
            console.log(error);
        }
    };
}
