// TODO: Refactor this with correct convention.
// The Enum Key should be SNAKE_CASED
export const enum NAVIGATION_KEY {
    Dashboard = 'dashboard',
    Invoices = 'invoices!',
    InvoicesPending = 'invoices!pending',
    InvoicesOutstanding = 'invoices!outstanding',
    InvoicesPaid = 'invoices!paid',
    InvoicesConsolidated = 'invoices!consolidated',
    NeedsAttention = 'needs-attention',
    Reminders = 'reminders',
    Statements = 'statements',
    StatementsSent = 'statements!sent',
    StatementsNotSent = 'statements!notSent',
    Admin = 'admin',
    AdminClientPortal = 'admin!clientPortal',
    AdminUsers = 'admin!users',
    AdminSettings = 'admin!settings',
    Clients = 'clients',
    Payments = 'payments',
    Matters = 'matters'
}
