import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agent';
import { SearchSelectItem } from '../common/components/searchSelect/SearchSelect';
import Filters from '../common/filter/filters';
import { ReminderListURLSearchParamBuilder } from '../common/utils/reminder';
import { PaginatedResult, PaginationParams } from '../models/list/pagination';
import { SearchParams } from '../models/list/search';
import {
    QUERYABLE_REMINDER_STATUS,
    Reminder,
    REMINDER_FILTER,
    ReminderFilters,
    ReminderStatusCount
} from '../models/reminder';
import { STORAGE_KEY } from '../models/user';
import { store } from './store';

export default class ReminderStore {
    private readonly FILTER_GROUP_ID = 'ReminderList';
    reminders: PaginatedResult<Reminder> | undefined;
    reminderStatusCount?: ReminderStatusCount[];
    loadingReminders = false;

    creatingReminder = false;
    updatingReminder = false;
    excludeAutoReminders = true;

    clientIdsFilterDefaultSelections?: SearchSelectItem[];
    ownersFilterDefaultSelections?: SearchSelectItem[];
    matterIdsFilterDefaultSelections?: SearchSelectItem[];

    reminderStatusFilter: QUERYABLE_REMINDER_STATUS = QUERYABLE_REMINDER_STATUS.ALL;

    reminderFilters = new Filters<keyof ReminderFilters>(
        this.FILTER_GROUP_ID,
        {
            [REMINDER_FILTER.MY_REMINDERS]: [],
            [REMINDER_FILTER.BILLING_TIME_KEEPERS]: [],
            [REMINDER_FILTER.CLIENTS]: [],
            [REMINDER_FILTER.MATTERS]: [],
            [REMINDER_FILTER.TAG_NAMES]: [],
            [REMINDER_FILTER.NOTE_OWNER_TYPE]: [],
            [REMINDER_FILTER.OWNERS]: []
        },
        () => this.resetPaginationParams()
    );

    pagingParams = {
        [QUERYABLE_REMINDER_STATUS.DUE]: new PaginationParams(),
        [QUERYABLE_REMINDER_STATUS.OVERDUE]: new PaginationParams(),
        [QUERYABLE_REMINDER_STATUS.UPCOMING]: new PaginationParams(),
        [QUERYABLE_REMINDER_STATUS.ALL]: new PaginationParams()
    };

    searchParams = new SearchParams();

    constructor() {
        makeAutoObservable(this);
    }

    getRemindersListParams = () => {
        const status = this.reminderStatusFilter;
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams[status].pageNumber.toString());
        this.pagingParams[status].pageSize = store.userStore.pageSize;
        params.append('pageSize', this.pagingParams[status].pageSize.toString());

        if (this.searchParams.searchString) {
            params.append('queryText', this.searchParams.searchString!);
        }
        params.append('orderBy', 'remindOn');

        return params;
    };

    private getReminderURLSearchParamsBuilder = () => {
        const paramsBuilder = new ReminderListURLSearchParamBuilder(this.getRemindersListParams());

        const selectedBillingTimekeeperIds =
            this.reminderFilters.filters[REMINDER_FILTER.BILLING_TIME_KEEPERS];
        const selectedClientIds = this.reminderFilters.filters[REMINDER_FILTER.CLIENTS];
        const selectedMatterIds = this.reminderFilters.filters[REMINDER_FILTER.MATTERS];
        const selectedTagNames = this.reminderFilters.filters[REMINDER_FILTER.TAG_NAMES];
        const selectedNoteOwnerTypes =
            this.reminderFilters.filters[REMINDER_FILTER.NOTE_OWNER_TYPE];
        const selectedAssigneeIds = this.reminderFilters.filters[REMINDER_FILTER.OWNERS];
        paramsBuilder
            .addBillingTimekeeperIds(selectedBillingTimekeeperIds)
            .addClientIds(selectedClientIds)
            .addMatterIds(selectedMatterIds)
            .addTagNames(selectedTagNames)
            .addNoteOwnerTypes(selectedNoteOwnerTypes)
            .addBillingTimekeeperIds(selectedBillingTimekeeperIds)
            .addAssigneeIds(selectedAssigneeIds);

        paramsBuilder.addExcludeAutoReminders(this.excludeAutoReminders);
        paramsBuilder.addMyReminders(
            !!this.reminderFilters.filters[REMINDER_FILTER.MY_REMINDERS].length
        );

        if (this.reminderStatusFilter !== QUERYABLE_REMINDER_STATUS.ALL) {
            paramsBuilder.addStatus(this.reminderStatusFilter);
        }

        return paramsBuilder;
    };

    setSearchParams = (searchParams: SearchParams) => {
        this.searchParams = searchParams;
    };

    setMyRemindersFilter = (myReminders: boolean) => {
        this.reminderFilters.updateFilter(
            REMINDER_FILTER.MY_REMINDERS,
            myReminders ? ['true'] : []
        );
    };

    setExcludeAutoReminderFilter = (excludeAutoReminders: boolean) => {
        this.excludeAutoReminders = excludeAutoReminders;
        localStorage.setItem(STORAGE_KEY.EXCLUDE_AUTO_REMINDERS, `${excludeAutoReminders}`);
    };

    setPagingParams = (pagingParams: PaginationParams) => {
        this.pagingParams[this.reminderStatusFilter] = pagingParams;
    };

    setReminderStatusFilter = (status: QUERYABLE_REMINDER_STATUS) => {
        this.reminderStatusFilter = status;
    };

    resetPaginationParams = () => {
        this.pagingParams[this.reminderStatusFilter] = new PaginationParams();
    };

    createReminder = async (noteId: string, remindOn: string) => {
        this.creatingReminder = true;
        try {
            const reminder = await agent.Notes.createReminder(noteId, remindOn);
            return reminder;
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.creatingReminder = false;
            });
        }
    };

    loadRemindersAndStatusCounts = () => {
        this.loadReminderStatusCount();
        this.loadReminders();
    };

    loadReminders = async () => {
        this.loadingReminders = true;
        try {
            const builder = this.getReminderURLSearchParamsBuilder();

            const reminders = await agent.Reminders.getAll(builder.urlSearchParams);

            // Reset the page number to total pages and
            // refetch the data if it exceeds total pages
            const totalPages = reminders.paginationInfo.totalPages || 1;
            if (this.pagingParams[this.reminderStatusFilter].pageNumber > totalPages) {
                this.setPagingParams(new PaginationParams(totalPages));
                await this.loadReminders();
                return;
            }
            runInAction(() => {
                this.reminders = reminders;
            });
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.loadingReminders = false;
            });
        }
    };

    markReminderAsDone = async (reminderId: string) => {
        this.updatingReminder = true;
        try {
            const reminder = await agent.Reminders.do(reminderId);
            return reminder;
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.updatingReminder = false;
            });
        }
    };

    undoReminder = async (reminderId: string) => {
        this.updatingReminder = true;
        try {
            const reminder = await agent.Reminders.undo(reminderId);
            return reminder;
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.updatingReminder = false;
            });
        }
    };

    editReminder = async (reminderId: string, remindOn: string) => {
        this.updatingReminder = true;
        try {
            const reminder = await agent.Reminders.update(reminderId, remindOn);
            return reminder;
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.updatingReminder = false;
            });
        }
    };

    deleteReminder = async (reminderId: string) => {
        this.updatingReminder = true;
        try {
            await agent.Reminders.delete(reminderId);
            return true;
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.updatingReminder = false;
            });
        }
    };

    loadReminderStatusCount = async () => {
        this.loadingReminders = true;
        const params = new URLSearchParams();
        if (this.reminderFilters.filters[REMINDER_FILTER.MY_REMINDERS].length) {
            params.append('myReminders', 'true');
        }
        if (this.excludeAutoReminders) {
            params.append('excludeAutoReminders', 'true');
        }
        try {
            const reminderStatusCount = await agent.Reminders.count(params);
            runInAction(() => {
                this.reminderStatusCount = reminderStatusCount;
            });
            return true;
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.loadingReminders = false;
            });
        }
    };

    updateClientIdsFilterDefaultSelections = (selections: SearchSelectItem[]) => {
        runInAction(() => {
            this.clientIdsFilterDefaultSelections = selections;
        });
    };

    updateOwnersFilterDefaultSelections = (selections: SearchSelectItem[]) => {
        runInAction(() => {
            this.ownersFilterDefaultSelections = selections;
        });
    };

    updateMatterIdsFilterDefaultSelections = (selections: SearchSelectItem[]) => {
        runInAction(() => {
            this.matterIdsFilterDefaultSelections = selections;
        });
    };
}
