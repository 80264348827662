import {
    AuditOutlined,
    ClockCircleFilled,
    ClockCircleOutlined,
    DollarCircleFilled,
    DollarCircleOutlined,
    ExclamationCircleFilled,
    ExclamationCircleOutlined,
    FileTextFilled,
    FileTextOutlined,
    HomeFilled,
    HomeOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    SettingFilled,
    SettingOutlined,
    UserOutlined
} from '@ant-design/icons';
import { Badge, Button, Flex, Image, Menu, Space, Typography } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { MenuProps } from 'antd/es/menu';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NAVIGATION_KEY } from '../../common/constants/navigationKey';
import { ErrorCounts } from '../../models/error';
import { REMINDER_FILTER } from '../../models/reminder';
import { useStore } from '../../stores/store';
import './SideBar.css';

type MenuItem = Required<MenuProps>['items'][number];

type NeedsAttentionDotProps = {
    collapsed: boolean;
    errorCounts?: ErrorCounts;
};

const getMenuItem = (
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group'
): MenuItem => {
    return {
        key: key,
        icon: icon,
        children,
        label,
        type,
        style: { zIndex: 10 }
    } as MenuItem;
};

const getOnlyMineFilter = (onlyMine: boolean) => (onlyMine ? 'my' : 'all');

export default observer(function SideBar() {
    const navigate = useNavigate();

    const {
        userStore: { user, userPreferences, setUserPreferences },
        invoiceStore: { resetPaginationParams: resetInvoiceListPaginationParams, myInvoices },
        uiStore: { selectedNavigationKey, uiPreferences },
        reminderStore: { reminderFilters },
        clientStore: { myClients },
        errorStore: { getErrorCounts, errorCounts },
        statementStore: { myStatements },
        tenantStore: { tenant },
        matterStore: { myMatters }
    } = useStore();

    const [collapsed, setCollapsed] = useState(userPreferences.siderCollapsed!);

    useEffect(() => {
        getErrorCounts();
    }, []);

    if (!user) {
        return null;
    }

    const handleCollapsibleIconClick = () => {
        setCollapsed(!collapsed);
        setUserPreferences({ siderCollapsed: !collapsed });
    };

    const items: MenuItem[] = [
        user.capabilities.viewDashboard
            ? getMenuItem(
                  'Dashboard',
                  NAVIGATION_KEY.Dashboard,
                  selectedNavigationKey === NAVIGATION_KEY.Dashboard ? (
                      <HomeFilled />
                  ) : (
                      <HomeOutlined />
                  )
              )
            : null,
        getMenuItem(
            'Invoices',
            NAVIGATION_KEY.Invoices,
            selectedNavigationKey.startsWith(NAVIGATION_KEY.Invoices) ? (
                <FileTextFilled />
            ) : (
                <FileTextOutlined />
            ),
            uiPreferences.consolidateInvoiceList
                ? [
                      getMenuItem('Receivables', NAVIGATION_KEY.InvoicesConsolidated),
                      getMenuItem('Paid', NAVIGATION_KEY.InvoicesPaid)
                  ]
                : [
                      getMenuItem('Pending', NAVIGATION_KEY.InvoicesPending),
                      getMenuItem('Outstanding', NAVIGATION_KEY.InvoicesOutstanding),
                      getMenuItem('Paid', NAVIGATION_KEY.InvoicesPaid)
                  ]
        ),
        user.capabilities.viewStatements
            ? getMenuItem(
                  'Statements',
                  NAVIGATION_KEY.Statements,
                  selectedNavigationKey.startsWith(NAVIGATION_KEY.Statements) ? (
                      <FileTextFilled />
                  ) : (
                      <FileTextOutlined />
                  ),
                  [
                      getMenuItem('Not Sent', NAVIGATION_KEY.StatementsNotSent),
                      getMenuItem('Sent', NAVIGATION_KEY.StatementsSent)
                  ]
              )
            : null,
        getMenuItem(
            'Clients',
            NAVIGATION_KEY.Clients,
            selectedNavigationKey === NAVIGATION_KEY.Clients ? <UserOutlined /> : <UserOutlined />
        ),
        user.capabilities.viewMatters
            ? getMenuItem(
                  'Matters',
                  NAVIGATION_KEY.Matters,
                  selectedNavigationKey === NAVIGATION_KEY.Matters ? (
                      <AuditOutlined />
                  ) : (
                      <AuditOutlined />
                  )
              )
            : null,
        user.capabilities.viewReminders
            ? getMenuItem(
                  'Reminders',
                  NAVIGATION_KEY.Reminders,
                  selectedNavigationKey === NAVIGATION_KEY.Reminders ? (
                      <ClockCircleFilled />
                  ) : (
                      <ClockCircleOutlined />
                  )
              )
            : null,
        user.capabilities.canViewOnlinePayments
            ? getMenuItem(
                  'Online Payments',
                  NAVIGATION_KEY.Payments,
                  selectedNavigationKey === NAVIGATION_KEY.Payments ? (
                      <DollarCircleFilled />
                  ) : (
                      <DollarCircleOutlined />
                  )
              )
            : null,
        user.capabilities.viewNeedsAttention
            ? getMenuItem(
                  <Space size={5}>
                      <Typography.Text style={{ color: collapsed ? 'white' : '' }}>
                          Needs Attention
                      </Typography.Text>
                      <Badge
                          size='small'
                          count={errorCounts?.totalCount}
                          style={{ fontSize: '10px' }}
                          overflowCount={999}
                      ></Badge>
                  </Space>,
                  NAVIGATION_KEY.NeedsAttention,
                  selectedNavigationKey === NAVIGATION_KEY.NeedsAttention ? (
                      <Space size={1} align='center'>
                          <ExclamationCircleFilled />
                          <NeedsAttentionDot collapsed={collapsed} errorCounts={errorCounts} />
                      </Space>
                  ) : (
                      <Space size={1} align='center'>
                          <ExclamationCircleOutlined />
                          <NeedsAttentionDot collapsed={collapsed} errorCounts={errorCounts} />
                      </Space>
                  )
              )
            : null,
        user.capabilities.canManageAdministration
            ? getMenuItem(
                  'Admin',
                  NAVIGATION_KEY.Admin,
                  selectedNavigationKey.startsWith(NAVIGATION_KEY.Admin) ? (
                      <SettingFilled />
                  ) : (
                      <SettingOutlined />
                  ),
                  [
                      getMenuItem('Client Portal', NAVIGATION_KEY.AdminClientPortal),
                      getMenuItem('Users', NAVIGATION_KEY.AdminUsers),
                      getMenuItem('Settings', NAVIGATION_KEY.AdminSettings)
                  ]
              )
            : null
    ];

    const onMenuClick: MenuProps['onClick'] = (e) => {
        if (selectedNavigationKey !== e.key) {
            // When invoiceFilters change, we reset the pagination params
            resetInvoiceListPaginationParams();
        }
        const onlyMyReminders = !!reminderFilters.filters[REMINDER_FILTER.MY_REMINDERS].length;
        switch (e.key) {
            case NAVIGATION_KEY.Dashboard:
                navigate('/');
                break;
            case NAVIGATION_KEY.InvoicesPending:
                navigate(`/invoices/pending/${getOnlyMineFilter(myInvoices)}`);
                break;
            case NAVIGATION_KEY.InvoicesOutstanding:
                navigate(`/invoices/outstanding/${getOnlyMineFilter(myInvoices)}`);
                break;
            case NAVIGATION_KEY.InvoicesPaid:
                navigate(`/invoices/paid/${getOnlyMineFilter(myInvoices)}`);
                break;
            case NAVIGATION_KEY.InvoicesConsolidated:
                navigate(`/invoices/consolidated/${getOnlyMineFilter(myInvoices)}`);
                break;
            case NAVIGATION_KEY.NeedsAttention:
                navigate('/needs-attention/all');
                break;
            case NAVIGATION_KEY.Reminders:
                navigate(`/reminders/${getOnlyMineFilter(onlyMyReminders)}`);
                break;
            case NAVIGATION_KEY.AdminClientPortal:
                navigate('/admin/client-portal');
                break;
            case NAVIGATION_KEY.AdminUsers:
                navigate('/admin/users');
                break;
            case NAVIGATION_KEY.AdminSettings:
                navigate('/admin/settings');
                break;
            case NAVIGATION_KEY.Clients:
                navigate(`/clients/${getOnlyMineFilter(myClients)}`);
                break;
            case NAVIGATION_KEY.Matters:
                navigate(`/matters/${getOnlyMineFilter(myMatters)}`);
                break;
            case NAVIGATION_KEY.Payments:
                navigate('/payments');
                break;
            case NAVIGATION_KEY.Statements:
                navigate('/statements');
                break;
            case NAVIGATION_KEY.StatementsSent:
                navigate(`/statements/sent/${getOnlyMineFilter(myStatements)}`);
                break;
            case NAVIGATION_KEY.StatementsNotSent:
                navigate(`/statements/notSent/${getOnlyMineFilter(myStatements)}`);
                break;
        }
    };

    return (
        <Sider trigger={null} collapsible collapsed={collapsed} width={'fit-content'}>
            <Flex vertical>
                <Button
                    className='collapse'
                    shape='circle'
                    variant='text'
                    size='small'
                    onClick={handleCollapsibleIconClick}
                    style={{ alignSelf: 'flex-end', margin: '12px -12px 0 0' }}
                    icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                />
                <Flex className='side-bar' vertical align='center' justify='space-between'>
                    <Menu
                        onClick={onMenuClick}
                        overflowedIndicator={false}
                        theme='light'
                        mode='inline'
                        selectedKeys={[selectedNavigationKey]}
                        items={items}
                        style={{
                            marginTop: '-24px',
                            border: 'none',
                            overflowY: 'auto',
                            flex: '1',
                            width: '100%'
                        }}
                        defaultOpenKeys={
                            (!collapsed && [
                                NAVIGATION_KEY.Admin,
                                NAVIGATION_KEY.Invoices,
                                NAVIGATION_KEY.Statements
                            ]) ||
                            undefined
                        }
                    />
                    {tenant && (
                        <div className='firm-logo'>
                            <Image
                                wrapperClassName={collapsed ? 'collapsed' : 'not-collapsed'}
                                width={collapsed ? 48 : 88}
                                src={tenant.logoUrl}
                                alt='Firm Logo'
                                preview={false}
                            />
                        </div>
                    )}
                </Flex>
            </Flex>
        </Sider>
    );
});

const NeedsAttentionDot = (props: NeedsAttentionDotProps) => {
    const { collapsed, errorCounts } = props;
    if (!collapsed || !errorCounts?.totalCount) {
        return null;
    }
    return <Badge size='small' dot={true} />;
};
