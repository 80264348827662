import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ConfigProvider } from 'antd';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ThemeColor } from './app/common/constants/color';
import App from './app/layout/App';
import ScrollToTop from './app/layout/ScrollToTop';
import './app/layout/styles.css';
import { FormValidationMessages } from './app/models/config/form/validationMessages';
import { store, StoreContext } from './app/stores/store';
import queryClient from './shared/api/queryClient';

export const router = createBrowserRouter([
    {
        path: '*',
        element: (
            <ConfigProvider
                form={{ validateMessages: FormValidationMessages }}
                componentSize='middle'
                theme={{
                    token: {
                        colorPrimary: ThemeColor.ColorPrimary,
                        colorError: ThemeColor.ColorError,
                        colorSuccess: ThemeColor.ColorSuccess,
                        colorWarning: ThemeColor.ColorWarning,
                        colorInfo: ThemeColor.ColorInfo,
                        colorTextBase: '#000000',
                        colorBgContainer: '#fefefe',
                        colorBgLayout: '#ffffff',
                        colorPrimaryBg: '#f0f0f0',
                        colorPrimaryBgHover: '#e0e0e0'
                    },
                    components: {
                        Button: {
                            colorPrimary: '#262626',
                            colorPrimaryBg: '#666666',
                            colorPrimaryBgHover: '#595959',
                            colorPrimaryBorder: '#4d4d4d',
                            colorPrimaryBorderHover: '#404040',
                            colorPrimaryHover: '#333333',
                            colorPrimaryActive: '#000000',
                            colorPrimaryTextHover: '#333333',
                            colorPrimaryText: '#262626',
                            colorPrimaryTextActive: '#000000'
                        },
                        Menu: {
                            itemBg: '#ffffff',
                            subMenuItemBg: '#ffffff',
                            itemSelectedColor: '#000000',
                            marginXS: 1,
                            marginXXS: 1,
                            subMenuItemSelectedColor: '#000000'
                        },
                        Tooltip: {
                            fontSize: 13
                        },
                        Descriptions: {
                            paddingLG: 8,
                            paddingSM: 4,
                            paddingXS: 2
                        },
                        Tabs: {
                            borderRadius: 0,
                            borderRadiusLG: 0
                        },
                        Segmented: {
                            colorBgLayout: '#EBEBEB',
                            boxShadowTertiary: 'none'
                        }
                    }
                }}
            >
                <QueryClientProvider client={queryClient}>
                    <ScrollToTop />
                    <App />
                    <ReactQueryDevtools client={queryClient} />
                </QueryClientProvider>
            </ConfigProvider>
        )
    }
]);

const root = ReactDOM.createRoot(
    document.getElementById('root') || (document.createElement('div') as HTMLElement)
);

// Wrapping the App with the ReactContext Provider is redundant in our case as we are using mobX
// and it cleverly creates a subscription model and updates the changed value in
// the subscribed Components. But in the development mode, during the hot module reload(HMR)
// there are multiple mobX store creations due to which testing is difficult as it renders
// exponentialy for each HMR. Thus wrapping it with a Context forces the component to subscribe to
// the latest mobX store. But the testing becomes difficult as it requires mocking of entire store.
// To cleverly avoid this we do the following.
if (StoreContext) {
    root.render(
        <StoreContext.Provider value={store}>
            <RouterProvider router={router} />
        </StoreContext.Provider>
    );
}
// This will be executed in testing mode
else {
    root.render(<RouterProvider router={router} />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
