import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agent';
import { getPaidOnSinceDate } from '../common/utils/invoice';
import {
    AgedAR,
    ARTotal,
    CLIENT_METRICS_SORT_ORDER,
    ClientMetricsWithClientIdentifier,
    CurrentMonthBillerSubmissions,
    dataCacheExpireTimeInSecs,
    InvoiceMetricsByStatus,
    InvoiceMetricsByYear,
    InvoiceMetricsTotals,
    MonthlyInvoiceOrPayment,
    PendingInvoiceByType,
    PendingInvoicesByBiller
} from '../models/dashboard';
import { PAID_ON_SINCE } from '../models/invoice';
import { store } from './store';

export default class DashboardStore {
    loadingPendingInvoicesByType = false;
    pendingInvoicesByType: PendingInvoiceByType[] = [];

    loadingPendingInvoicesByBiller = false;
    pendingInvoicesByBiller: PendingInvoicesByBiller[] = [];

    loadingMonthlyPayments = false;
    monthlyPayments?: MonthlyInvoiceOrPayment[];

    loadingMonthlyInvoices = false;
    monthlyInvoices?: MonthlyInvoiceOrPayment[];

    loadingMonthlyBacklog = false;
    monthlyBacklog?: MonthlyInvoiceOrPayment[];

    loadingARTotal = false;
    arTotal?: ARTotal;
    loadingAgedAR = false;
    agedAR?: AgedAR[];

    loadingClientMetrics = false;
    clientMetrics?: ClientMetricsWithClientIdentifier[];
    clientMetricsByAROverdue?: ClientMetricsWithClientIdentifier[];
    clientMetricsByAvgDSO?: ClientMetricsWithClientIdentifier[];

    loadingCurrentMonthBillerSubmissions = false;
    currentMonthBillerSubmissions?: CurrentMonthBillerSubmissions[];

    loadingInvoiceMetricsByYear = false;
    invoiceMetricsByYear?: InvoiceMetricsByYear[];

    loadingARMetrics = false;
    arMetrics?: InvoiceMetricsTotals;

    loadingMyInvoiceMetricsByStatus = false;
    myInvoiceMetricsByStatus?: InvoiceMetricsByStatus;

    constructor() {
        makeAutoObservable(this);
    }

    isCacheExpired = (key: string) => {
        return store.cacheStore.isCacheExpired(key, dataCacheExpireTimeInSecs);
    };

    clearCache = () => {
        this.monthlyPayments = undefined;
        this.monthlyInvoices = undefined;
        this.monthlyBacklog = undefined;
    };

    getPendingInvoicesByType = async () => {
        try {
            // This chart is not cached.
            this.loadingPendingInvoicesByType = true;
            const pendingInvoices = await agent.Charts.getPendingInvoicesByType();
            runInAction(() => {
                this.pendingInvoicesByType = pendingInvoices;
            });
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.loadingPendingInvoicesByType = false;
            });
        }
    };

    get cummulativePendingAmount() {
        return this.pendingInvoicesByType?.reduce((total: number, item: PendingInvoiceByType) => {
            return total + item.amount;
        }, 0);
    }

    get totalPendingInvoicesCount() {
        return this.pendingInvoicesByType?.reduce((total: number, item: PendingInvoiceByType) => {
            return total + item.count;
        }, 0);
    }

    get pendingAmountPercentageByType() {
        const countPercentage: any = {};
        this.pendingInvoicesByType?.map((item: PendingInvoiceByType) => {
            return (countPercentage[item.invoiceType] =
                (item.amount * 100) / this.cummulativePendingAmount);
        });
        return countPercentage;
    }

    getMonthlyPayments = async () => {
        try {
            const cacheKey = 'MonthlyPayments';

            if (!this.monthlyPayments || this.isCacheExpired(cacheKey)) {
                this.loadingMonthlyPayments = true;
                const recentPaymentsByMonth = await agent.Charts.getPaymentsDataByMonth();
                runInAction(() => {
                    this.monthlyPayments = recentPaymentsByMonth;
                    store.cacheStore.updateCacheTimestamp(cacheKey);
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.loadingMonthlyPayments = false;
            });
        }
    };

    getPendingInvoicesByBiller = async () => {
        try {
            // This chart is not cached.
            this.loadingPendingInvoicesByBiller = true;
            const pendingInvoices = await agent.Charts.getPendingInvoicesByBiller();
            runInAction(() => {
                this.pendingInvoicesByBiller = pendingInvoices;
            });
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.loadingPendingInvoicesByBiller = false;
            });
        }
    };

    getMonthlyInvoices = async () => {
        try {
            const cacheKey = 'MonthlyInvoices';

            if (!this.monthlyInvoices || this.isCacheExpired(cacheKey)) {
                this.loadingMonthlyInvoices = true;
                const monthlyInvoices = await agent.Charts.getMonthlyInvoices();
                runInAction(() => {
                    this.monthlyInvoices = monthlyInvoices;
                    store.cacheStore.updateCacheTimestamp(cacheKey);
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.loadingMonthlyInvoices = false;
            });
        }
    };

    getMonthlyBacklog = async () => {
        try {
            const cacheKey = 'MonthlyBacklog';
            if (!this.monthlyBacklog || this.isCacheExpired(cacheKey)) {
                this.loadingMonthlyBacklog = true;
                const monthlyBacklog = await agent.Charts.getMonthlyBacklog();
                runInAction(() => {
                    this.monthlyBacklog = monthlyBacklog;
                    store.cacheStore.updateCacheTimestamp(cacheKey);
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.loadingMonthlyBacklog = false;
            });
        }
    };

    getARTotal = async () => {
        try {
            const cacheKey = 'ARByTotal';
            if (!this.arTotal || this.isCacheExpired(cacheKey)) {
                this.loadingARTotal = true;
                const arByTotal = await agent.Charts.getARByTotal();
                runInAction(() => {
                    this.arTotal = arByTotal;
                    store.cacheStore.updateCacheTimestamp(cacheKey);
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.loadingARTotal = false;
            });
        }
    };

    getAgedAR = async () => {
        try {
            const cacheKey = 'AgedAR';
            if (!this.agedAR || this.isCacheExpired(cacheKey)) {
                this.loadingAgedAR = true;
                const agedAR = await agent.Charts.getAgedAR();
                runInAction(() => {
                    this.agedAR = agedAR;
                    store.cacheStore.updateCacheTimestamp(cacheKey);
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.loadingAgedAR = false;
            });
        }
    };

    loadClientMetrics = async (orderBy: CLIENT_METRICS_SORT_ORDER) => {
        try {
            const cacheKey = `clientMetricsBy${orderBy}`;
            let shouldLoadMetrics = false;
            const isOrderByArOverdue = orderBy === CLIENT_METRICS_SORT_ORDER.AR_OVERDUE;
            if (isOrderByArOverdue && !this.clientMetricsByAROverdue) {
                shouldLoadMetrics = true;
            } else if (!this.clientMetricsByAvgDSO) {
                shouldLoadMetrics = true;
            }

            if (shouldLoadMetrics || this.isCacheExpired(cacheKey)) {
                this.loadingClientMetrics = true;
                const params = new URLSearchParams();
                params.append('orderBy', `-${orderBy}`);
                if (!isOrderByArOverdue) {
                    params.append('excludeNonEstablishedAvgDso', 'true');
                }
                const clientMetrics = await agent.Charts.getClientMetrics(params);
                runInAction(() => {
                    if (isOrderByArOverdue) {
                        this.clientMetricsByAROverdue = clientMetrics;
                    } else {
                        this.clientMetricsByAvgDSO = clientMetrics;
                    }
                    store.cacheStore.updateCacheTimestamp(cacheKey);
                });
            }
            runInAction(() => {
                this.clientMetrics = isOrderByArOverdue
                    ? this.clientMetricsByAROverdue
                    : this.clientMetricsByAvgDSO;
            });
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.loadingClientMetrics = false;
            });
        }
    };

    getInvoiceMetricsByYear = async () => {
        try {
            const cacheKey = 'invoiceMetricsByYear';
            if (!this.invoiceMetricsByYear || this.isCacheExpired(cacheKey)) {
                this.loadingInvoiceMetricsByYear = true;
                const invoiceMetricsByYear = await agent.Charts.getInvoiceMetricsByYear();
                runInAction(() => {
                    this.invoiceMetricsByYear = invoiceMetricsByYear;
                    store.cacheStore.updateCacheTimestamp(cacheKey);
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.loadingInvoiceMetricsByYear = false;
            });
        }
    };

    getCurrentMonthBillerSubmissions = async () => {
        try {
            const cacheKey = 'CurrentMonthBillerSubmissions';
            if (!this.currentMonthBillerSubmissions || this.isCacheExpired(cacheKey)) {
                this.loadingCurrentMonthBillerSubmissions = true;
                const currentMonthBillerSubmissions =
                    await agent.Charts.getCurrentMonthBillerSubmissions();
                runInAction(() => {
                    this.currentMonthBillerSubmissions = currentMonthBillerSubmissions;
                    store.cacheStore.updateCacheTimestamp(cacheKey);
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.loadingCurrentMonthBillerSubmissions = false;
            });
        }
    };

    getARMetrics = async () => {
        try {
            const cacheKey = 'arMetrics';
            if (!this.arMetrics || this.isCacheExpired(cacheKey)) {
                this.loadingARMetrics = true;
                const arMetrics = await agent.Charts.getInvoiceMetricsTotals();
                runInAction(() => {
                    this.arMetrics = arMetrics;
                    store.cacheStore.updateCacheTimestamp(cacheKey);
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.loadingARMetrics = false;
            });
        }
    };

    loadMyInvoiceMetricsByStatus = async () => {
        try {
            const cacheKey = 'myInvoiceMetricsByStatus';
            if (!this.myInvoiceMetricsByStatus || this.isCacheExpired(cacheKey)) {
                this.loadingMyInvoiceMetricsByStatus = true;
                const params = new URLSearchParams();
                params.append('paidOnSince', getPaidOnSinceDate(PAID_ON_SINCE.LAST_30_DAYS));
                const myInvoiceMetricsByStatus =
                    await agent.Charts.getMyInvoiceMetricsByStatus(params);
                runInAction(() => {
                    this.myInvoiceMetricsByStatus = myInvoiceMetricsByStatus;
                    store.cacheStore.updateCacheTimestamp(cacheKey);
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.loadingMyInvoiceMetricsByStatus = false;
            });
        }
    };

    getWidgetTitle = (title: string, isTimekeeper?: boolean) => {
        if (isTimekeeper) {
            return 'My ' + title;
        } else {
            return title;
        }
    };
}
