import { ClientIdentifier } from './client';
import { InvoiceIdentifier } from './invoice';
import { MatterIdentifier } from './matter';
import { NOTE_OWNER_TYPE, NoteAttachmentDescriptor } from './note';

export type Reminder = {
    assigneeId: string;
    assigneeName: string;
    autoReminderType: AUTO_REMINDER_TYPE | null;
    canDo: boolean | null;
    canEdit: boolean;
    canUndo: boolean | null;
    createdById: string;
    createdByName: string;
    createdOn: Date;
    id: string;
    modifiedOn: Date;
    remindOn: Date;
    status: REMINDER_STATUS;
    note: ReminderNote;
};

export enum AUTO_REMINDER_TYPE {
    UPCOMING_AR_BUCKET_1_DUE = 'UpcomingArBucket1Due',
    UPCOMING_AR_BUCKET_2_DUE = 'UpcomingArBucket2Due',
    UPCOMING_AR_BUCKET_3_DUE = 'UpcomingArBucket3Due',
    UPCOMING_AR_BUCKET_4_DUE = 'UpcomingArBucket4Due',
    UPCOMING_OVERDUE = 'UpcomingOverdue',
    UPCOMING_PAYMENT_PLAN_INSTALLMENT_DUE = 'UpcomingPaymentPlanInstallmentDue'
}

export type ReminderNote = {
    attachments: NoteAttachmentDescriptor[];
    client: ClientIdentifier;
    content: string;
    id: string;
    invoice: InvoiceIdentifier | null;
    matter: MatterIdentifier | null;
    ownerType: NOTE_OWNER_TYPE;
    tagNames: string[];
};

export enum REMINDER_STATUS {
    UPCOMING = 'Upcoming',
    DUE = 'Due',
    OVERDUE = 'Overdue',
    DONE = 'Done'
}

export enum QUERYABLE_REMINDER_STATUS {
    UPCOMING = 'Upcoming',
    DUE = 'Due',
    OVERDUE = 'Overdue',
    ALL = 'All'
}

export enum REMINDER_ACTION {
    DO = 'do',
    UNDO = 'undo',
    EDIT = 'edit',
    DELETE = 'delete'
}

export enum REMINDER_SELECTION_TYPE {
    NEXT_DAY = 'nextday',
    ONE_WEEK = 'oneWeek',
    TWO_WEEKS = 'twoWeek',
    CUSTOM_DATE = 'customDate'
}

export type ReminderStatusCount = {
    status: string;
    count: number;
};

export enum REMINDER_FILTER {
    MY_REMINDERS = 'myReminders',

    CLIENTS = 'clientIds',
    MATTERS = 'matterIds',
    TAG_NAMES = 'tagNames',
    BILLING_TIME_KEEPERS = 'timekeeperIds',
    NOTE_OWNER_TYPE = 'noteOwnerTypes',
    OWNERS = 'assigneeIds'
}

export type ReminderFilters = {
    [REMINDER_FILTER.MY_REMINDERS]: string[];
    [REMINDER_FILTER.CLIENTS]: string[];
    [REMINDER_FILTER.MATTERS]: string[];
    [REMINDER_FILTER.TAG_NAMES]: string[];
    [REMINDER_FILTER.BILLING_TIME_KEEPERS]: string[];
    [REMINDER_FILTER.NOTE_OWNER_TYPE]: string[];
    [REMINDER_FILTER.OWNERS]: string[];
};
