export enum EMAIL_TEMPLATE_TYPE {
    CLIENT_REMINDER_EMAIL = 'ClientReminderEmail',
    INVOICE_SUBMISSION_EMAIL = 'InvoiceSubmissionEmail'
}

export enum EMAIL_PLACEHOLDER {
    NOTE = 'Note'
}

export interface EmailTemplate {
    templateType: EMAIL_TEMPLATE_TYPE;
    placeholderName: EMAIL_PLACEHOLDER;
    name: string;
    value: string;
    id: string;
}
