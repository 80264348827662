import { ClientFilters } from './client';
import { InvoiceFilters } from './invoice';

export type LoggedInUser = User & {
    capabilities: Capabilities;

    // TODO: We can remove this and use the userUtils,
    // We can refactor this later, as it is a bit involved
    isTimekeeper?: boolean;
};

export interface User {
    userId: string;
    billerId: string;
    billingManagerId: string;
    displayName: string;
    email: string;
    firstName: string;
    lastName: string;
    roles: ROLE[];
    permissions: PERMISSION[];
    secretaryId: string;
    loginId: string;
}

export interface UserPatchProperties {
    roles: ROLE[];
}

export enum AUTH_GRANT_TYPE {
    PASSWORD = 'password',
    AUTHORIZATION_CODE = 'authorization_code',
    CLIENT_CREDENTIALS = 'client_credentials',
    REFRESH_TOKEN = 'refresh_token'
}

/**
 * This conforms to the backend service TokenRequestParams which can be
 * used to get the access token in three ways i.e using threes grant types
 */
export interface OAuthTokenRequestProps {
    // The name of the fields conform to oidc convention.
    code?: string;
    client_id?: string;
    client_secret?: string;
    grant_type: AUTH_GRANT_TYPE;
    refresh_token?: string;
    username?: string;
    password?: string;
}

export interface OAuthTokenResponse {
    access_token: string;
    expires_in: number;
    refresh_token: string | null;
    refresh_expires_in: number;
    userName: string;
    userId: string;
}

export interface Token {
    access_token: string;
    refresh_token: string | null;
    access_token_expiration_ts: number;
    refresh_token_expiration_ts: number;
}

export interface Claim {
    key: string;
    value: string;
}

export interface UserPreferences {
    siderCollapsed?: boolean;
    pageSize?: number;
    invoiceListFilters?: InvoiceFilters;
    clientListFilters?: ClientFilters;
}

export interface Capabilities {
    canManageAdministration: boolean;
    viewDashboard: boolean;
    viewMatters: boolean;
    viewPayments: boolean;
    viewReminders: boolean;
    viewNeedsAttention: boolean;
    canSyncConversations: boolean;
    canManageConversations: boolean;
    canViewOnlinePayments: boolean;
    canDownloadEntities: boolean;
    viewStatements: boolean;
}

export enum ROLE {
    ADMIN = 'admin',
    BILLING_MANAGER = 'billingManager',
    BILLER = 'biller',
    SECRETARY = 'secretary',
    TIME_KEEPER = 'timekeeper',
    COLLECTOR = 'collector',
    EBILLING_SERVICES = 'eBillingServices',
    VIEWER = 'viewer'
}

export enum PERMISSION {
    CONVERSATION_MANAGER = 'ConversationManager',
    EXEMPT_FROM_CLIENT_MATTER_SECURITY = 'ExemptFromClientMatterSecurity'
}

export enum STORAGE_KEY {
    ODDR_USER_PREFERENCES = 'oddrUserPreferences',
    TOKEN = 'elixirJSON',
    REDIRECT_URL = 'redirectURL',
    MY_INVOICES = 'myInvoices',
    MY_CLIENTS = 'myClients',
    MY_STATEMENTS = 'myStatements',
    MY_MATTERS = 'myMatters',
    EXCLUDE_AUTO_REMINDERS = 'excludeAutoReminders'
}

export type UserLookup = {
    displayName: string;
    userId: string;
    id: string;
};

export enum UserFilter {
    ROLES = 'roles'
}

export interface UserFilters {
    [UserFilter.ROLES]: string[];
}
