import { makeAutoObservable } from 'mobx';
import { router } from '../../index';
import { NAVIGATION_KEY } from '../common/constants/navigationKey';
import { UserUtils } from '../common/utils/user';
import { INVOICE_STATE, INVOICE_TYPE } from '../models/invoice';
import { STATEMENT_STATE } from '../models/statement';
import { UiPreferences } from '../models/uiPreferences';
import { LoggedInUser, STORAGE_KEY } from '../models/user';
import { StaticRoutes } from '../routing/RoutePaths';
import { store } from './store';

export default class UiStore {
    selectedNavigationKey: NAVIGATION_KEY = NAVIGATION_KEY.Dashboard;

    uiPreferences: UiPreferences = {
        consolidateInvoiceList: false,
        homePage: '/'
    };

    constructor() {
        makeAutoObservable(this);
    }

    setNavigationKey = (navigator: NAVIGATION_KEY) => {
        this.selectedNavigationKey = navigator;
    };

    setNavigationKeyByInvoiceState = (invoiceState?: INVOICE_STATE) => {
        switch (invoiceState) {
            case INVOICE_STATE.PENDING:
                this.setNavigationKey(NAVIGATION_KEY.InvoicesPending);
                break;
            case INVOICE_STATE.OUTSTANDING:
                this.setNavigationKey(NAVIGATION_KEY.InvoicesOutstanding);
                break;
            case INVOICE_STATE.PAID:
                this.setNavigationKey(NAVIGATION_KEY.InvoicesPaid);
                break;
            case INVOICE_STATE.CONSOLIDATED:
                this.setNavigationKey(NAVIGATION_KEY.InvoicesConsolidated);
                break;
            default:
                this.setNavigationKey(NAVIGATION_KEY.Invoices);
        }
    };

    setNavigationKeyByStatementState = (statementState?: STATEMENT_STATE) => {
        switch (statementState) {
            case STATEMENT_STATE.SENT:
                this.setNavigationKey(NAVIGATION_KEY.StatementsSent);
                break;
            case STATEMENT_STATE.NOT_SENT:
                this.setNavigationKey(NAVIGATION_KEY.StatementsNotSent);
                break;
            default:
                this.setNavigationKey(NAVIGATION_KEY.Statements);
        }
    };

    setUiPreferences = (user: LoggedInUser) => {
        if (!user) {
            return;
        }

        const userUtils = new UserUtils(user);

        this.uiPreferences.consolidateInvoiceList = userUtils.isTimekeeper;
        this.uiPreferences.homePage = userUtils.isEBillingServices
            ? StaticRoutes.INVOICE_LIST_PENDING
            : '/';

        if (store.userStore.iseBillingServicesUser) {
            store.invoiceStore.setInvoiceTypeFilter(INVOICE_TYPE.EBILLING);
        }

        // Set the myEntities default values
        store.invoiceStore.setMyInvoicesFilter(
            this.setDefaultMyEntities(localStorage.getItem(STORAGE_KEY.MY_INVOICES), userUtils)
        );
        store.statementStore.setMyStatementFilter(
            this.setDefaultMyEntities(localStorage.getItem(STORAGE_KEY.MY_STATEMENTS), userUtils)
        );
        store.clientStore.setMyClientsFilter(
            this.setDefaultMyEntities(localStorage.getItem(STORAGE_KEY.MY_CLIENTS), userUtils)
        );
        store.matterStore.setMyMattersFilter(
            this.setDefaultMyEntities(localStorage.getItem(STORAGE_KEY.MY_MATTERS), userUtils)
        );
        // This logic is temporary and can be removed in the next release.
        // It was added because existing filters should not affect the users.
        let excludeAutoRemindersPreference = localStorage.getItem(
            STORAGE_KEY.EXCLUDE_AUTO_REMINDERS
        );
        if (!excludeAutoRemindersPreference) {
            excludeAutoRemindersPreference = JSON.parse(
                localStorage.getItem('ReminderListFilter') || '{}'
            )?.excludeAutoReminders?.[0];
        }
        store.reminderStore.setExcludeAutoReminderFilter(excludeAutoRemindersPreference === 'true');
    };

    private setDefaultMyEntities = (myEntities: string | null, userUtils: UserUtils) => {
        return myEntities ? myEntities === 'true' : userUtils.canViewMyEntities;
    };

    redirectUserPostLogin = () => {
        const redirectURL = store.userStore.getRedirectUrl();
        router.navigate(redirectURL ?? this.uiPreferences.homePage);
    };
}
