import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agent';
import { NOTES_PAGE_SIZE } from '../common/constants/note';
import Filters from '../common/filter/filters';
import { URLParams } from '../common/utils/urlParams';
import { PaginatedResult, PaginationParams } from '../models/list/pagination';
import { SearchParams } from '../models/list/search';
import {
    GetNotesParams,
    NOTE_FILTER,
    NOTE_OWNER_TYPE,
    Note,
    NoteAttachment,
    NoteFilters
} from '../models/note';
import { PAGE_KEY } from '../models/pageConfigs';

export enum NOTE_PIN_OPERATION {
    PIN = 'pin',
    UN_PIN = 'unPin'
}

export enum GET_NOTES_QUERY_PARAM {
    REMINDERS_ONLY = 'remindersOnly',
    NOTE_OWNER_TYPES = 'noteOwnerTypes',
    EXCLUDE_AUTO_REMINDERS = 'excludeAutoReminders'
}

export default class NoteStore {
    private readonly FILTER_GROUP_ID = 'noteList';
    currentPageKey: PAGE_KEY = PAGE_KEY.INVOICE_DETAILS_NOTES;
    notes?: PaginatedResult<Note>;
    creatingNote = false;
    updatingNote = false;
    deletingNote = false;
    fetchingNotes = false;

    attachments: NoteAttachment[] = [];
    creatingAttachments = false;
    deletingAttachment = false;
    loadingAttachments = false;
    loadingAttachmentContent = false;

    sharingNote = false;
    fetchingSharedToMatters = false;

    urlParams = new URLParams(
        [
            PAGE_KEY.CLIENT_PROFILE_NOTES,
            PAGE_KEY.INVOICE_DETAILS_NOTES,
            PAGE_KEY.MATTER_PROFILE_NOTES
        ],
        {
            [PAGE_KEY.CLIENT_PROFILE_NOTES]: NOTES_PAGE_SIZE,
            [PAGE_KEY.INVOICE_DETAILS_NOTES]: NOTES_PAGE_SIZE,
            [PAGE_KEY.MATTER_PROFILE_NOTES]: NOTES_PAGE_SIZE
        }
    );

    noteFilters = new Filters<keyof NoteFilters>(
        this.FILTER_GROUP_ID,
        {
            [NOTE_FILTER.EXCLUDE_AUTO_REMINDERS]: ['exclude'],
            [NOTE_FILTER.REMINDERS_ONLY]: [],
            [NOTE_FILTER.TAG_NAMES]: []
        },
        () => this.setPaginationParams(new PaginationParams())
    );

    // Client/Matter notes specific filters
    onlyClientNotes = false;
    onlyMatterNotes = false;

    pinningOrUnPinningNote = false;

    fetchingAssignedNoteTags = false;
    updatingAssignedNoteTags = false;

    constructor() {
        makeAutoObservable(this);
    }

    setCurrentPageKey = (
        pageKey:
            | PAGE_KEY.INVOICE_DETAILS_NOTES
            | PAGE_KEY.MATTER_PROFILE_NOTES
            | PAGE_KEY.CLIENT_PROFILE_NOTES
    ) => {
        this.currentPageKey = pageKey;
    };

    setSearchParams = (searchParams: SearchParams) => {
        this.urlParams.setSearchParams(this.currentPageKey, searchParams);
    };

    getSearchParams = () => {
        return this.urlParams.getSearchParams(this.currentPageKey);
    };

    setPaginationParams = (paginationParams: PaginationParams) => {
        this.urlParams.setPaginationParams(this.currentPageKey, paginationParams);
    };

    setOnlyClientNotes = (onlyClientNotes: boolean) => {
        this.onlyClientNotes = onlyClientNotes;
    };

    setOnlyMatterNotes = (onlyMatterNotes: boolean) => {
        this.onlyMatterNotes = onlyMatterNotes;
    };

    getUrlParams = () => {
        const params = new URLSearchParams();
        const paginationParams = this.urlParams.getPaginationParams(this.currentPageKey);
        params.append('pageNumber', paginationParams.pageNumber.toString());
        params.append('pageSize', paginationParams.pageSize.toString());

        const searchParams = this.urlParams.getSearchParams(this.currentPageKey);
        if (searchParams.searchString) {
            params.append('queryText', searchParams.searchString);
        }
        return params;
    };

    getNotes = async (params: GetNotesParams) => {
        const { ownerType } = params;
        this.fetchingNotes = true;
        let notes_: PaginatedResult<Note> | undefined;
        const onlyReminders = !!this.noteFilters.filters[NOTE_FILTER.REMINDERS_ONLY].length;
        const excludeAutoReminders =
            !!this.noteFilters.filters[NOTE_FILTER.EXCLUDE_AUTO_REMINDERS].length;

        const urlParams = this.getUrlParams();

        this.noteFilters.filters[NOTE_FILTER.TAG_NAMES].forEach((selectedTag) =>
            urlParams.append('tagNames', selectedTag)
        );
        if (onlyReminders) {
            urlParams.append(GET_NOTES_QUERY_PARAM.REMINDERS_ONLY, 'true');
        }
        if (excludeAutoReminders) {
            urlParams.append(GET_NOTES_QUERY_PARAM.EXCLUDE_AUTO_REMINDERS, 'true');
        }

        try {
            switch (ownerType) {
                case NOTE_OWNER_TYPE.CLIENT: {
                    if (this.onlyClientNotes) {
                        urlParams.append(GET_NOTES_QUERY_PARAM.NOTE_OWNER_TYPES, 'Client');
                    }
                    notes_ = await agent.Clients.getNotes(params.clientId, urlParams);
                    break;
                }
                case NOTE_OWNER_TYPE.INVOICE: {
                    const { invoiceId } = params;
                    notes_ = await agent.Invoices.getNotes(invoiceId, urlParams);
                    break;
                }
                case NOTE_OWNER_TYPE.MATTER: {
                    const { clientId, matterId } = params;
                    if (this.onlyMatterNotes) {
                        urlParams.append(GET_NOTES_QUERY_PARAM.NOTE_OWNER_TYPES, 'Matter');
                    }
                    notes_ = await agent.Matters.getNotes(clientId, matterId, urlParams);
                    break;
                }
            }

            // Reset the page number to total pages and
            // refetch the data if it exceeds total pages
            const totalPages = notes_.paginationInfo.totalPages || 1;
            if (this.urlParams.getPaginationParams(this.currentPageKey).pageNumber > totalPages) {
                this.setPaginationParams(new PaginationParams(totalPages));
                await this.getNotes(params);
                return;
            }
            if (notes_) {
                runInAction(() => {
                    this.notes = notes_;
                });
            }
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => {
                this.fetchingNotes = false;
            });
        }
    };

    createNote = async (
        content: string,
        ownerType: NOTE_OWNER_TYPE,
        sharedToMatterIds: string[],
        tagKeys: string[],
        clientId?: string,
        matterId?: string,
        invoiceId?: string
    ) => {
        let newNote;
        if (ownerType === NOTE_OWNER_TYPE.INVOICE && !invoiceId) {
            throw new Error('[invoiceId] is required for [Invoice] ownerType');
        }
        if (ownerType === NOTE_OWNER_TYPE.CLIENT && !clientId) {
            throw new Error('[clientId] is required for [Client] ownerType');
        }
        if (ownerType === NOTE_OWNER_TYPE.MATTER && !(clientId && matterId)) {
            throw Error('[clientId] and [matterId] is required for [Matter] ownerType');
        }
        this.creatingNote = true;
        try {
            switch (ownerType) {
                case NOTE_OWNER_TYPE.CLIENT: {
                    const newNote_ = await agent.Clients.createNote(clientId!, content, tagKeys);
                    newNote = newNote_;
                    break;
                }
                case NOTE_OWNER_TYPE.MATTER: {
                    const newNote_ = await agent.Matters.createNote(
                        clientId!,
                        matterId!,
                        content,
                        sharedToMatterIds,
                        tagKeys
                    );
                    newNote = newNote_;
                    break;
                }
                default: {
                    const newNote_ = await agent.Invoices.createNote(invoiceId!, content, tagKeys);
                    newNote = newNote_;
                    break;
                }
            }
        } catch (error: any) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.creatingNote = false;
            });
        }
        return newNote;
    };

    updateNote = async (noteId: string, content: string) => {
        this.updatingNote = true;
        try {
            const updatedNote = await agent.Notes.updateNote(noteId, content);
            return updatedNote;
        } catch (error: any) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.updatingNote = false;
            });
        }
    };

    deleteNote = async (noteId: string) => {
        this.deletingNote = true;
        try {
            const deletedNote = await agent.Notes.deleteNote(noteId);
            return deletedNote;
        } catch (error: any) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.deletingNote = false;
            });
        }
    };

    createAttachments = async (noteId: string, files: File[]) => {
        this.creatingAttachments = true;
        let success = false;
        for (let i = 0; i < files.length; ++i) {
            try {
                const formaData = new FormData();
                formaData.append('Content', files[i]);
                await agent.Notes.createAttachment(noteId, formaData);
                success = true;
            } catch (err) {
                console.log(err);
            }
        }
        runInAction(() => {
            this.creatingAttachments = false;
        });
        return success;
    };

    deleteAttachment = async (noteId: string, attachmentId: string) => {
        this.deletingAttachment = true;
        try {
            await agent.Notes.deleteAttachment(noteId, attachmentId);
            return true;
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => {
                this.deletingAttachment = false;
            });
        }
    };

    getAttachmentContent = async (noteId: string, attachmentId: string) => {
        this.loadingAttachmentContent = true;
        try {
            const content = await agent.Notes.getAttachmentContent(noteId, attachmentId);
            return content;
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => {
                this.loadingAttachmentContent = false;
            });
        }
    };

    getAttachments = async (noteId: string) => {
        this.loadingAttachments = true;
        try {
            const attachments = await agent.Notes.getAttachments(noteId);
            runInAction(() => {
                this.attachments = attachments;
            });
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => {
                this.loadingAttachments = false;
            });
        }
    };

    clearAttachments = () => {
        this.attachments = [];
    };

    shareMatterNote = async (noteId: string, sharedToMatterIds: string[]) => {
        this.sharingNote = true;
        try {
            await agent.Notes.shareMatterNote(noteId, sharedToMatterIds);
            return true;
        } catch (err) {
            console.log(err);
            return false;
        } finally {
            runInAction(() => {
                this.sharingNote = false;
            });
        }
    };

    getSharedToMatters = async (noteId: string) => {
        this.fetchingSharedToMatters = true;
        try {
            const sharedToMatters = await agent.Notes.getSharedToMatters(noteId);
            return sharedToMatters;
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => {
                this.fetchingSharedToMatters = false;
            });
        }
    };

    pinOrUnPinNote = async (noteId: string, operation: NOTE_PIN_OPERATION) => {
        this.pinningOrUnPinningNote = true;
        try {
            if (operation === NOTE_PIN_OPERATION.PIN) {
                await agent.Notes.pinNote(noteId);
            } else {
                await agent.Notes.unPinNote(noteId);
            }
            return true;
        } catch (err) {
            console.log(err);
            return false;
        } finally {
            runInAction(() => {
                this.pinningOrUnPinningNote = false;
            });
        }
    };

    getAssignedNoteTags = async (noteId: string) => {
        this.fetchingAssignedNoteTags = true;
        try {
            const noteTags = await agent.Notes.getAssignedNoteTags(noteId);
            return noteTags;
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => {
                this.fetchingAssignedNoteTags = false;
            });
        }
    };

    updateAssignedNoteTags = async (noteId: string, tagKeys: string[]) => {
        this.updatingAssignedNoteTags = true;
        try {
            await agent.Notes.updateAssignedNoteTags(noteId, tagKeys);
            return true;
        } catch (err) {
            console.log(err);
            return false;
        } finally {
            runInAction(() => {
                this.updatingAssignedNoteTags = false;
            });
        }
    };

    clearNoteStore = () => {
        this.notes = undefined;
        this.onlyClientNotes = false;
        this.attachments = [];
        this.urlParams.reinitializeParams([
            PAGE_KEY.CLIENT_PROFILE_NOTES,
            PAGE_KEY.INVOICE_DETAILS_NOTES
        ]);
        // Just clearing the reminders only
        this.noteFilters.updateFilter(NOTE_FILTER.REMINDERS_ONLY, []);
    };
}
