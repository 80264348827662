import apiClient from '../../shared/api/apiClient';
import { CreateOrUpdatePaymentPlan, PaymentPlan } from '../models/paymentPlan';

const PaymentPlans = {
    BASE_URL: '/paymentplans',
    getPaymentPlanIdentifierUrl: (paymentPlanId: string) =>
        `${PaymentPlans.BASE_URL}/${paymentPlanId}`,
    updatePaymentPlan: (paymentPlanId: string, body: CreateOrUpdatePaymentPlan) =>
        apiClient.put<PaymentPlan>(PaymentPlans.getPaymentPlanIdentifierUrl(paymentPlanId), {
            ...body
        }),
    completePaymentPlan: (paymentPlanId: string, noteContent: string | null) =>
        apiClient.post<void>(
            `${PaymentPlans.getPaymentPlanIdentifierUrl(paymentPlanId)}/_complete`,
            { noteContent }
        )
};

export default PaymentPlans;
