import { makeAutoObservable, reaction, runInAction } from 'mobx';
import agent from '../api/agent';
import Filters from '../common/filter/filters';
import { getExpirationInMs } from '../common/utils/datetime';
import { Notify } from '../common/utils/notify';
import { URLParams } from '../common/utils/urlParams';
import { UserUtils } from '../common/utils/user';
import { PaginatedResult, PaginationParams } from '../models/list/pagination';
import { SearchParams } from '../models/list/search';
import { SortingParams } from '../models/list/sorting';
import { PAGE_KEY } from '../models/pageConfigs';
import { PAYMENT_TYPE } from '../models/payment';
import {
    AUTH_GRANT_TYPE,
    Capabilities,
    LoggedInUser,
    OAuthTokenRequestProps,
    OAuthTokenResponse,
    PERMISSION,
    ROLE,
    STORAGE_KEY,
    User,
    UserFilter,
    UserFilters,
    UserPatchProperties,
    UserPreferences
} from '../models/user';
import { store } from './store';

export default class UserStore {
    private readonly FILTER_GROUP_ID = 'UserList';
    readonly DEFAULT_PAGE_SIZE = 25;
    readonly MAX_PAGE_SIZE = 100;
    user: LoggedInUser | null = null;
    pageSize: number = this.DEFAULT_PAGE_SIZE;
    loggingIn = false;
    loggingOut = false;
    loadingCurrentUser = false;
    loadingUsers = false;
    updatingUser = false;
    preferences: UserPreferences = {
        siderCollapsed: false,
        pageSize: this.DEFAULT_PAGE_SIZE
    };

    users: PaginatedResult<User> | undefined;

    urlParams = new URLParams([PAGE_KEY.USER_LIST]);

    userFilters = new Filters<keyof UserFilters>(
        this.FILTER_GROUP_ID,
        { [UserFilter.ROLES]: [] },
        () => this.resetUserListPaginationParams()
    );

    constructor() {
        makeAutoObservable(this);

        const preferences = localStorage.getItem(STORAGE_KEY.ODDR_USER_PREFERENCES);
        if (preferences) {
            this.preferences = JSON.parse(preferences);
            this.fixPageSize();
        } else {
            localStorage.setItem(
                STORAGE_KEY.ODDR_USER_PREFERENCES,
                JSON.stringify(this.preferences)
            );
        }

        // Here pageSize's only purpose is to make it easier to access in other
        // stores, hence we use it as a derived state from preferences
        this.pageSize = this.preferences.pageSize ?? this.DEFAULT_PAGE_SIZE;
        reaction(
            () => this.preferences.pageSize,
            (pageSize) => (this.pageSize = pageSize ?? this.DEFAULT_PAGE_SIZE)
        );
    }

    get isLoggedIn() {
        return !!this.user;
    }

    get isCUser() {
        // We will enhance this further , for now only checking for admin
        return this.user && this.user?.roles.includes(ROLE.ADMIN);
    }

    get iseBillingServicesUser() {
        return this.user?.roles.length === 1 && this.user.roles[0] === ROLE.EBILLING_SERVICES;
    }

    get userPreferences() {
        return this.preferences;
    }

    get UserListUrlParams() {
        const params = new URLSearchParams();

        const paginationParams = this.urlParams.getPaginationParams(PAGE_KEY.USER_LIST);
        paginationParams.pageSize = store.userStore.pageSize;
        params.append('pageNumber', paginationParams!.pageNumber.toString());
        params.append('pageSize', paginationParams!.pageSize.toString());

        const sortingParams = this.urlParams.getSortingParams(PAGE_KEY.USER_LIST);
        if (sortingParams!.sortExpression.length > 0) {
            params.append('orderBy', sortingParams!.sortExpression);
        }

        const searchParams = this.urlParams.getSearchParams(PAGE_KEY.USER_LIST);
        if (searchParams!.searchString) {
            params.append('queryText', searchParams!.searchString);
        }
        return params;
    }

    fixPageSize = () => {
        // Page size of 200 causes perf issues. Limit to MAX_PAGE_SIZE.
        // There may be some users with prefs set to 200. This routine is to take care of that.
        if (this.preferences.pageSize && this.preferences.pageSize > this.MAX_PAGE_SIZE) {
            this.preferences.pageSize = this.MAX_PAGE_SIZE;
        }
    };

    getPaginationParams = (pageKey: PAGE_KEY) => {
        return this.urlParams.getPaginationParams(pageKey);
    };

    getSortingParams = (pageKey: PAGE_KEY) => {
        return this.urlParams.getSortingParams(pageKey);
    };

    getSearchParams = (pageKey: PAGE_KEY) => {
        return this.urlParams.getSearchParams(pageKey);
    };

    setPaginationParams = (pageKey: PAGE_KEY, paginationParams: PaginationParams) => {
        this.urlParams.setPaginationParams(pageKey, paginationParams);
    };

    setSortingParams = (pageKey: PAGE_KEY, sortingParams: SortingParams) => {
        this.urlParams.setSortingParams(pageKey, sortingParams);
        this.urlParams.setPaginationParams(pageKey, new PaginationParams());
    };

    setSearchParams = (pageKey: PAGE_KEY, searchParams: SearchParams) => {
        this.urlParams.setSearchParams(pageKey, searchParams);
        this.urlParams.setPaginationParams(pageKey, new PaginationParams());
    };

    resetUserListPaginationParams = () => {
        this.setPaginationParams(PAGE_KEY.PAYMENT_LIST, new PaginationParams());
    };
    setUserPreferences = (preferences: UserPreferences) => {
        this.preferences = { ...this.preferences, ...preferences };
        window.localStorage.setItem(
            STORAGE_KEY.ODDR_USER_PREFERENCES,
            JSON.stringify(this.preferences)
        );
    };

    setRedirectUrl = (url: string | null) => {
        if (url) {
            sessionStorage.setItem(STORAGE_KEY.REDIRECT_URL, url);
        } else {
            sessionStorage.removeItem(STORAGE_KEY.REDIRECT_URL);
        }
    };

    getRedirectUrl = () => {
        return sessionStorage.getItem(STORAGE_KEY.REDIRECT_URL);
    };

    getSSOUrl = async () => {
        const ssoUrl = await agent.Identity.getSSOUrl();
        return ssoUrl;
    };

    loginUsingUsernameAndPassword = async (username: string, password: string) => {
        const oAuthTokenRequestProps: OAuthTokenRequestProps = {
            grant_type: AUTH_GRANT_TYPE.PASSWORD,
            client_id: 'invoice-app',
            username,
            password
        };

        return await this.login(oAuthTokenRequestProps);
    };

    loginUsingIdP = async (authCode: string) => {
        const oAuthTokenRequestProps: OAuthTokenRequestProps = {
            code: authCode,
            grant_type: AUTH_GRANT_TYPE.AUTHORIZATION_CODE
        };

        return await this.login(oAuthTokenRequestProps);
    };

    login = async (oAuthTokenRequestProps: OAuthTokenRequestProps) => {
        this.loggingIn = true;
        try {
            const oAuthTokenResponse: OAuthTokenResponse =
                await agent.Identity.createToken(oAuthTokenRequestProps);

            runInAction(() => {
                if (!oAuthTokenResponse.access_token) {
                    return false;
                }
                store.commonStore.setToken(oAuthTokenResponse);
            });

            await this.loadUser();
            return this.isLoggedIn;
        } catch (error) {
            console.log(error);
            return false;
        } finally {
            runInAction(() => {
                this.loggingIn = false;
            });
        }
    };

    refreshTokenUseable = () => {
        if (!store.commonStore.token) {
            return false;
        }
        const refreshTokenExpirationIn = getExpirationInMs(
            store.commonStore.token.refresh_token_expiration_ts
        );

        // check if the refresh token is valid for at least 5 more seconds.
        const usable = refreshTokenExpirationIn >= 5000;
        console.log(usable ? 'Refresh token is usable' : 'Refresh token has expired');
        return usable;
    };

    accessTokenUsable = () => {
        if (!store.commonStore.token) {
            return false;
        }
        return getExpirationInMs(store.commonStore.token.access_token_expiration_ts) >= 10000;
    };

    accessTokenExpiredOrAboutToExpire = () => {
        if (!store.commonStore.token) {
            return true;
        }
        const accessTokenExpirationIn = getExpirationInMs(
            store.commonStore.token.access_token_expiration_ts
        );

        // Refresh the access token if the access token is about to expire
        if (accessTokenExpirationIn < 20000) {
            console.log('Access token has expired or is expiring soon');
            return true;
        }

        return false;
    };

    refreshAccessToken = async () => {
        let tokenRefreshed = false;
        try {
            const refreshToken = store.commonStore.token?.refresh_token;
            if (refreshToken) {
                const oAuthTokenRequestProps: OAuthTokenRequestProps = {
                    grant_type: AUTH_GRANT_TYPE.REFRESH_TOKEN,
                    client_id: 'invoice-app',
                    refresh_token: refreshToken
                };
                const oAuthTokenResponse = await agent.Identity.createToken(oAuthTokenRequestProps);
                runInAction(() => {
                    if (oAuthTokenResponse.access_token) {
                        store.commonStore.setToken(oAuthTokenResponse);
                        console.log('Access token refreshed');
                        tokenRefreshed = true;
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }

        return tokenRefreshed;
    };

    logout = async (userInitiated = false) => {
        if (!this.isLoggedIn) {
            this.loggingOut = false;
            return;
        }

        try {
            this.loggingOut = true;
            this.setRedirectUrl(userInitiated ? null : location.pathname);

            // We will call the logout endpoint only if refresh token is valid
            // If invalid it already implies the SSO logout has happened in the ID service
            if (this.refreshTokenUseable()) {
                await agent.Identity.logout();
            }
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                store.commonStore.setToken(null);
                this.loggingOut = false;
                const navigateTo =
                    store.tenantStore.tenant?.idPConfigured && !userInitiated
                        ? (this.getRedirectUrl() ?? '/')
                        : '/login';
                console.log('Redirecting to: ' + navigateTo);
                location.replace(navigateTo);
            });
        }
    };

    loadUser = async () => {
        this.loadingCurrentUser = true;
        try {
            const userEntity = await agent.Auth.getCurrentUser();
            const user: LoggedInUser = {
                ...userEntity,
                capabilities: this.getCapabilities(userEntity),
                // TODO: We have been conditionally doing few things when the user is timekeeper.
                // Things might break when user has other roles including timekeeper.
                // When refactoring, we should mindfully design the role based access
                isTimekeeper: userEntity.roles.includes(ROLE.TIME_KEEPER)
            };
            runInAction(() => {
                this.user = user;
                store.uiStore.setUiPreferences(user);
            });
        } catch (error) {
            // Since 401 unauthorized will be caught in the axios interceptor, it will log the user out.
            console.log(error);
        } finally {
            runInAction(() => {
                this.loadingCurrentUser = false;
            });
        }
    };

    handle401Exception = async (detail: string) => {
        // clear the token
        runInAction(() => {
            store.commonStore.setToken(null);
        });

        if (this.loggingIn) {
            if (this.loadingCurrentUser) {
                // loading user - user is not provisioned could be possible here
                Notify.info(detail ?? 'User is not authorized', 'Unauthorized');
            } else {
                // login or refresh token call
                Notify.info('Please check your credentials and try again', 'Unable to login');
            }
        } else {
            // Likely token expired or not valid. Logout should be called.
            console.log(detail ?? 'User is not authorized', 'Unauthorized');
            this.logout();
        }
    };

    getCapabilities = (user: User) => {
        const userUtils = new UserUtils(user);
        const capabilities: Capabilities = {
            canManageAdministration: userUtils.isAdmin,
            viewDashboard: userUtils.canViewAll || userUtils.isTimekeeper,
            viewPayments: userUtils.canViewAll,
            viewMatters: userUtils.canViewAll,
            viewReminders: userUtils.canViewAll,
            viewNeedsAttention: userUtils.canViewAll,
            canSyncConversations:
                userUtils.isAdmin && !!store.tenantStore.tenant?.platformTestModeEnabled,
            canManageConversations: userUtils.hasPermissions(PERMISSION.CONVERSATION_MANAGER),
            canDownloadEntities: userUtils.canManageEntities || userUtils.isTimekeeper,
            canViewOnlinePayments:
                userUtils.canViewAll &&
                store.tenantStore.tenant?.paymentType === PAYMENT_TYPE.PAYLOAD,
            viewStatements: userUtils.canViewAll || userUtils.isTimekeeper
        };

        return capabilities;
    };

    // Admin settings
    getAllUsers = async () => {
        try {
            this.loadingUsers = true;
            const urlParams = this.UserListUrlParams;
            const filters = this.userFilters.filters;
            filters[UserFilter.ROLES].forEach((role) => urlParams.append('roles', role));
            const users = await agent.Users.getAll(urlParams);
            runInAction(() => {
                this.users = users;
            });
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.loadingUsers = false;
            });
        }
    };

    getUser = async (userId: string) => {
        try {
            const urlParams = new URLSearchParams();
            urlParams.append('userId', userId);
            const user = agent.Users.get(userId);
            return user;
        } catch (error) {
            console.log(error);
        }
    };

    patchUser = async (userId: string, patchProperties: UserPatchProperties) => {
        this.updatingUser = true;
        try {
            await agent.Users.patchUser(userId, patchProperties);
            return true;
        } catch (error) {
            console.log(error);
            return false;
        } finally {
            runInAction(() => {
                this.updatingUser = false;
            });
        }
    };

    lookup = async (queryText: string, roles?: ROLE[]) => {
        try {
            const urlParams = new URLSearchParams({ queryText });
            roles?.forEach((role) => {
                urlParams.append('roles', role);
            });
            const users = await agent.Users.lookup(urlParams);
            return users;
        } catch (error) {
            console.log(error);
        }
    };
}
