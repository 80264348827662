import apiClient from '../../shared/api/apiClient';
import { TimekeeperLookup } from '../models/timeKeeper';

const Timekeepers = {
    BASE_URL: '/timekeepers',
    lookup: (params: URLSearchParams) =>
        apiClient.get<TimekeeperLookup[]>(`${Timekeepers.BASE_URL}/lookup`, { params })
};

export default Timekeepers;
