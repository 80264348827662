import { Tag } from '../../shared/models/tags';
import { ClientIdentifier } from './client';
import { InvoiceIdentifier } from './invoice';
import { MatterIdentifier } from './matter';
import { REMINDER_STATUS } from './reminder';

export interface Note {
    attachments: NoteAttachmentDescriptor[];
    canDelete: boolean;
    canEdit: boolean;
    content: string;
    client: ClientIdentifier;
    matter: MatterIdentifier | null;
    invoice: InvoiceIdentifier | null;
    createdById: string;
    createdByName: string;
    createdOn: Date;
    deleted: boolean;
    edited: boolean;
    id: string;
    modifiedOn: Date;
    ownerType: NOTE_OWNER_TYPE;
    reminder: NoteReminder | null;
    reminderDue: boolean;
    shared: boolean;
    pinned: boolean;
    tagNames: string[];
}

export interface NoteReminder {
    assigneeId: string;
    assigneeName: string;
    canDo: boolean;
    canEdit: boolean;
    canUndo: boolean;
    id: string;
    remindOn: Date;
    autoReminderType: string | null;
    status: REMINDER_STATUS;
}

export enum NOTE_OWNER_TYPE {
    INVOICE = 'Invoice',
    MATTER = 'Matter',
    CLIENT = 'Client'
}

export interface NoteAttachmentDescriptor {
    fileName: string;
    id: string;
}

export interface NoteAttachment {
    attachmentName: string;
    createdById: string;
    createdByName: string;
    createdOn: Date;
    id: string;
    modifiedOn: Date;
}

export type GetNotesParams = {
    ownerType: NOTE_OWNER_TYPE;
} & (
    | {
          ownerType: NOTE_OWNER_TYPE.MATTER;
          matterId: string;
          clientId: string;
      }
    | {
          ownerType: NOTE_OWNER_TYPE.CLIENT;
          clientId: string;
      }
    | {
          ownerType: NOTE_OWNER_TYPE.INVOICE;
          invoiceId: string;
      }
);

export type AssignedNoteTag = {
    noteTag: Tag;
};

export enum NOTE_FILTER {
    REMINDERS_ONLY = 'remindersOnly',
    EXCLUDE_AUTO_REMINDERS = 'excludeAutoReminders',
    TAG_NAMES = 'tagNames'
}

export type NoteFilters = {
    [NOTE_FILTER.REMINDERS_ONLY]: string[];
    [NOTE_FILTER.EXCLUDE_AUTO_REMINDERS]: string[];
    [NOTE_FILTER.TAG_NAMES]: string[];
};
