import apiClient from '../../shared/api/apiClient';
import { OAuthTokenRequestProps, OAuthTokenResponse } from '../models/user';
import { store } from '../stores/store';

const Identity = {
    OAUTH_CREATE_TOKEN_URL: '/api/v1/oauth2/token',
    OAUTH_LOGOUT_URL: '/api/v1/oauth2/logout',
    OAUTH_SSO_URL: '/api/v1/oauth2/sso/url',
    getSSOUrl: () => {
        const baseUrl = store.tenantStore.tenant?.identityServiceUrl;
        return apiClient.get<string>(`${baseUrl}${Identity.OAUTH_SSO_URL}`);
    },
    createToken: (oAuthTokenRequestProps: OAuthTokenRequestProps) => {
        const baseUrl = store.tenantStore.tenant?.identityServiceUrl;
        return apiClient.post<OAuthTokenResponse>(
            `${baseUrl}${Identity.OAUTH_CREATE_TOKEN_URL}`,
            oAuthTokenRequestProps
        );
    },
    logout: () => {
        const baseUrl = store.tenantStore.tenant?.identityServiceUrl;
        const refreshToken = store.commonStore.token?.refresh_token;
        if (!refreshToken) {
            return;
        }
        return apiClient.post(
            `${baseUrl}${Identity.OAUTH_LOGOUT_URL}`,
            {
                refresh_token: refreshToken
            },
            {
                // All status codes are fine for logout and should not
                // result in AxiosError being thrown.
                validateStatus: null
            }
        );
    }
};

export default Identity;
