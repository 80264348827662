import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import { router } from '../..';
import Tenants from '../../app/api/tenants';
import { Notify } from '../../app/common/utils/notify';
import { getBaseInvoiceServiceAPIURL } from '../../app/common/utils/tenant';
import { RESOURCE_TYPE } from '../../app/models/app';
import { PaginatedResult } from '../../app/models/list/pagination';
import { store } from '../../app/stores/store';

let refreshTokenPromise: Promise<boolean> | null;

const resourceTypeRegExps = {
    [RESOURCE_TYPE.GET_INVOICE_LOOKUP]: /^get\/v1\/invoices\/lookup[^/]+$/i,
    [RESOURCE_TYPE.GET_INVOICE]: /^get\/v1\/invoices\/[^/]+$/i
} as const;

const getResourceType = (method: Method, url: string) => {
    for (const [key, value] of Object.entries(resourceTypeRegExps)) {
        if (value.test(method + url)) {
            return key as RESOURCE_TYPE;
        }
    }
};

const sleep = (delay: number) => {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
};

const urlRequiresAuthentication = (requestUrl: string | undefined) => {
    return (
        !requestUrl?.includes(`/${API_VERSION}/oauth2/`) && !requestUrl?.includes(Tenants.ME_URL)
    );
};

const API_VERSION = 'v1';
const BASE_URL = `${getBaseInvoiceServiceAPIURL()}/${API_VERSION}`;

const axiosClient = axios.create({
    baseURL: BASE_URL
});

axiosClient.defaults.baseURL = `${getBaseInvoiceServiceAPIURL()}/${API_VERSION}`;

axiosClient.interceptors.request.use(async (config) => {
    // Check if token needs to be and can be refreshed.
    if (
        urlRequiresAuthentication(config.url) &&
        store.userStore.accessTokenExpiredOrAboutToExpire() &&
        store.userStore.refreshTokenUseable()
    ) {
        // The refresh token is valid and let's try to refresh the access token
        if (!refreshTokenPromise) {
            // No other request is refreshing the token. Claim the rights to do it
            refreshTokenPromise = store.userStore
                .refreshAccessToken()
                .then((tokenRefreshed) => {
                    if (!tokenRefreshed) {
                        store.userStore.logout();
                    }
                    return tokenRefreshed;
                })
                .finally(() => (refreshTokenPromise = null));
        }

        // Another request is refreshing the token.
        // If current access token is still usable, dont wait and continue making the API call,
        // but if it is not usable, block until the other request updates the access token.
        if (
            !store.userStore.accessTokenUsable() &&
            refreshTokenPromise &&
            !(await refreshTokenPromise)
        ) {
            // refreshing the token failed.
            // log as error
            console.log('Unable to get an updated token.');
            // This will abort the call to the backend and will directly be
            // caught in the response interceptor
            throw new axios.Cancel();
        }
    }

    console.log(config.url + (config.params ? '?' + config.params : ''));
    const token = store.commonStore.token?.access_token;
    if (token) {
        config.headers!.Authorization = `Bearer ${token}`;
    }
    return config;
});

axiosClient.interceptors.response.use(
    async (response) => {
        if (process.env.NODE_ENV === 'development') {
            await sleep(500);
        }
        if (Object.hasOwn(response.data, 'pageNumber')) {
            response.data = new PaginatedResult(response.data);
        }
        return response;
    },
    async (error: AxiosError) => {
        const response: AxiosResponse = error.response!;

        if (axios.isCancel(error)) {
            return Promise.reject();
        }

        if (!response) {
            Notify.error('Please make sure you are connected to the internet and try again.');
            return Promise.reject(error);
        }

        const { data, status, config } = response;
        switch (status) {
            case 400:
                if (typeof data === 'string') {
                    Notify.error(data);
                }
                if (
                    config.method === 'get' &&
                    (Object.hasOwn(data.errors, 'id') || Object.hasOwn(data.errors, 'number'))
                ) {
                    router.navigate('/not-found');
                }
                if (data.errors) {
                    for (const key in data.errors) {
                        Notify.error(key + ': ' + data.errors[key].toString());
                    }
                }
                break;
            case 401:
                // there was too much logic to be handled here.
                // moved the 401 exception handling to the userStore.
                store.userStore.handle401Exception(data.detail);
                break;
            case 403:
                Notify.error('User cannot perform this operation', 'Forbidden');
                break;
            case 404:
                // This shouldn't happen for a non GET request.If in case 404 is returned for a non GET request
                // we shouldn't navigate them to NotFound, as error notification will be shown.
                // So just adding this condition as an hedge
                if (/get/i.test(config.method ?? '')) {
                    router.navigate(
                        `/not-found?resourceType=${getResourceType('get', config.url ?? '')}`
                    );
                }
                break;
            case 410:
            case 422:
                Notify.error(data.detail);
                break;
            case 500:
                if (data && typeof data.detail === 'string') {
                    Notify.error(data.detail);
                } else {
                    router.navigate('/server-error');
                }
                break;
        }
        store.modalStore.closeModal();
        return Promise.reject(error);
    }
);

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const apiClient = {
    get: <T>(url: string, config?: AxiosRequestConfig) =>
        axiosClient.get<T>(url, config).then(responseBody),
    post: <T>(url: string, data: {} = {}, config?: AxiosRequestConfig) =>
        axiosClient.post<T>(url, data, config).then(responseBody),
    put: <T>(url: string, data: {} = {}, config?: AxiosRequestConfig) =>
        axiosClient.put<T>(url, data, config).then(responseBody),
    delete: <T>(url: string, config?: AxiosRequestConfig) =>
        axiosClient.delete<T>(url, config).then(responseBody),
    patch: <T>(url: string, data: {} = {}) => axiosClient.patch<T>(url, data).then(responseBody)
};

export default apiClient;
