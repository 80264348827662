import { Activity } from './activity';
import { ClientIdentifier, RelatedClientIdentifier } from './client';
import { EBillingStatus } from './dictionary';
import { IdName, KeyName, KeyValuePair } from './keyValuePair';
import { MatterType } from './matter';
import { Note } from './note';
import { Payment } from './payment';
import { Person } from './person';

export interface DocumentVersion {
    createdById: string;
    createdByName: string;
    createdOn: Date;
    documentName: string;
    documentType: 'Invoice';
    versionId: string;
}

export enum DocumentDetailsActions {
    ACTIVITY = 'activity',
    COMMENTS = 'comments',
    VERSIONS = 'versions'
}

export interface DocumentStatusAggregates {
    items: KeyValuePair<
        string,
        { count: number; totalAmount: number; statusName: INVOICE_STATUS }
    >[];
}

export enum FILE_TYPE {
    PDF = 'pdf',
    TXT = 'txt'
}

export const supportedUploadFileTypes = {
    ePdf: [FILE_TYPE.PDF],
    Physical: [FILE_TYPE.PDF],
    eBilling: [FILE_TYPE.TXT, FILE_TYPE.PDF],
    statement: [FILE_TYPE.PDF]
};

export interface InvoiceActivity extends Activity {
    statusName: string;
    status: INVOICE_STATUS;
    exception: boolean;
    services: boolean;
    payor: {
        name: string;
        payorId: string;
    } | null;
}

export interface Invoice {
    activities: InvoiceActivity[];
    billerId: string;
    billerName: string;
    billingTimekeeperId: string;
    billingTimekeeperName: string;
    canAnnotate: boolean | null;
    canDownload: boolean | null;
    canShare: boolean | null;
    canSubmit: boolean | null;
    canUpload: boolean | null;
    canUpdateType: boolean | null;
    canSendForReview: boolean | null;
    canCompleteReview: boolean | null;
    canResubmit: boolean | null;
    canRequestWriteOff: boolean | null;
    canSendForSubmission: boolean | null;
    canManageAttachments: boolean;
    canManageEBillingStatus: boolean | null;
    canMarkAsSubmitted: boolean | null;
    canCompleteWriteOff: boolean | null;
    canClearError: boolean | null;
    client: ClientIdentifier &
        RelatedClientIdentifier & {
            billingInstructions: string | null;
        };
    collectionTimekeeperId: string | null;
    collectionTimekeeperName: string | null;
    createdById: string;
    createdOn: Date;
    currency: Currency;
    dueOn: Date | null;
    defaultInvoiceSubmissionNote: IdName | null;
    error: string | null;
    foreignCurrency: InvoiceForeignCurrency;
    id: string;
    invoiceAmount: number;
    invoiceDocumentName: string | null;
    invoiceLedesName: string | null;
    invoiceId: string;
    invoicedOn: Date | null;
    invoiceDocumentAttachmentsCount: number | null;
    invoiceDocumentAnnotated: boolean;
    matter: {
        billingInstructions: string | null;
        matterId: string;
        name: string;
        matterType: MatterType;
        leadMatter: boolean;
    };
    modifiedById: string;
    modifiedOn: Date;
    number: string;
    paid: boolean;
    paidAmount: number | null;
    paidOn: Date | null;
    parentNumber: string | null;
    parentInvoiceId: string | null;
    paymentMethod: KeyName | null;
    payors: InvoicePayor[];
    payor: {
        payorId: string;
        name: string;
    } | null;
    payorPerson: Person | null;
    reversed: boolean;
    reversedOn: string | null;
    reminderDue: boolean;
    status: INVOICE_STATUS;
    statusName: string;
    submitException: SUBMIT_EXCEPTION | null;
    submittedOn: Date | null;
    type: INVOICE_TYPE;
    typeName: string;
    comments: InvoiceComment[];
    notes: Note[];
    documentVersions: DocumentVersion[];
    hasMultipleVersionsOfDocument: boolean;
    eBillingStatusUpdatedOn: Date | null;
    eBillingVendorName: string | null;
    eBillingVendorUrl: string | null;
    eBillingStatus: EBillingStatus | null;
    recipients: string[] | null;
    doNotContact: boolean;
    hasDaysToViewAnomaly: boolean;
    hasDsoAnomaly: boolean;
    thirdParty: boolean;
    writeOffStatus: WRITE_OFF_STATUS | null;
    writeOffAmount: number | null;
    payorInvoice: boolean;
    payments: Payment[];
    emailOpensCount: number | null;
    invoiceViewsCount: number | null;
}

export type InvoiceIdentifier = {
    invoiceId: string;
    number: string;
};

export interface InvoiceDocumentAttachment {
    attachmentName: string;
    canDelete: boolean;
    canEdit: boolean;
    createdById: string;
    createdByName: string;
    createdOn: Date;
    description: string;
    id: string;
    modifiedOn: Date;
}

export type InvoiceProperty = 'Activities' | 'DocumentVersions' | 'Notes' | 'Payors' | 'Payments';

export enum INVOICE_TYPE {
    EPDF = 'ePdf',
    PHYSICAL = 'Physical',
    EBILLING = 'eBilling'
}

export enum INVOICE_TYPE_DISPLAY_NAME {
    EPDF = 'ePDF',
    EBILLING = 'eBilling',
    PHYSICAL = 'Physical'
}

export enum INVOICE_STATE {
    PENDING = 'pending',
    OUTSTANDING = 'outstanding',
    PAID = 'paid',
    CONSOLIDATED = 'consolidated',
    NEEDS_ATTENTION = 'needs-attention',
    REVERSED = 'reversed'
}

export enum INVOICE_STATUS {
    ADJUSTED = 'Adjusted',
    APPEALED = 'Appealed',
    APPROVED = 'Approved',
    DELIVERED = 'Delivered',
    IN_CLIENT_REVIEW = 'InClientReview',
    IN_REVIEW = 'InReview',
    IN_REVISION = 'InRevision',
    OTHER = 'Other',
    PAID = 'Paid',
    PAYMENT_SENT = 'PaymentSent',
    READY_TO_REVIEW = 'ReadyToReview',
    READY_TO_SUBMIT = 'ReadyToSubmit',
    REJECTED = 'Rejected',
    REVERSED = 'Reversed',
    SUBMITTED = 'Submitted',
    VIEWED = 'Viewed'
}

export enum INVOICE_SUB_GROUP_STATUS {
    // Pending
    IN_REVIEW = 'InReview',
    NOT_SUBMITTED = 'NotSubmitted',

    // Outstanding
    SUBMITTED = 'Submitted',
    APPEALED = 'Appealed',
    ADJUSTED = 'Adjusted',
    REJECTED = 'Rejected',
    APPROVED = 'Approved',
    OTHERS = 'Others',

    // Completed
    PAID = 'Paid',
    REVERSED = 'Reversed'
}

export enum SUBMIT_EXCEPTION {
    IN_REVIEW = 'InReview',
    WITH_TIMEKEEPER = 'WithTimekeeper'
}

export enum WRITE_OFF_STATUS {
    PENDING = 'Pending',
    COMPLETED = 'Completed'
}

export enum WRITE_OFF_RESPONSE {
    COMPLETED = 'Completed',
    ALREADY_PROCESSED = 'alreadyProcessed',
    FAILED = 'failed'
}

export interface ApproverDetails {
    approver: {
        name: string;
        userId: string;
    };
    comment: string | null;
    level: number;
    status: string;
}

export interface WriteOff {
    amount: number;
    comment: string;
    requestor: {
        name: string;
        userId: string;
    };
    completed: ApproverDetails[];
    pending: ApproverDetails;
    status: string;
    upcoming: ApproverDetails[];
    invoiceWriteOffReason: KeyName | null;
}

export enum INVOICE_STATUS_ORDER {
    ReadyToReview,
    InReview,
    InRevision,
    ReadyToSubmit,
    Submitted,
    Delivered,
    Viewed,
    InClientReview,
    Appealed,
    Adjusted,
    Rejected,
    Approved,
    PaymentSent,
    Other,
    Paid,
    Reversed
}

export interface InvoiceComment {
    canDelete: boolean;
    canEdit: boolean;
    content: string;
    createdById: string;
    createdByName: string;
    createdOn: Date;
    deleted: boolean;
    edited: boolean;
    id: string;
    modifiedOn: Date;
}

export enum VIEWABLE_DOCUMENT_TYPE {
    LEDES = 'ledes',
    PDF = 'pdf'
}

export interface InvoiceLookup {
    invoiceId: string;
    matterName: string;
    number: string;
}

export interface InvoiceStatus {
    invoiceStatus: string;
    invoiceStatusName: string;
}

// Payor models

export interface InvoicePayorPayor {
    eBillingVendor: KeyName | null;
    eBillingVendorUrl: string | null;
    emails: string[];
    name: string;
    payorId: string;
    phoneNumbers: string;
}

export interface InvoicePayorInvoice {
    canDownload: boolean | null;
    canShare: boolean | null;
    canUpload: boolean | null;
    canSubmit: boolean | null;
    canResubmit: boolean | null;
    canMarkAsSubmitted: boolean | null;
    canManageAttachments: boolean | null;
    canManageEBillingStatus: boolean | null;
    dueOn: Date | null;
    eBillingStatus: KeyName | null;
    eBillingVendorName: string | null;
    eBillingVendorUrl: string | null;
    error: string | null;
    id: string;
    number: string;
    invoiceAmount: number;
    invoiceDocumentName: string | null;
    invoicedOn: Date;
    invoiceId: string;
    invoiceLedesName: string | null;
    paidAmount: number | null;
    paidOn: Date | null;
    payorPerson: Person | null;
    paymentMethod: KeyName | null;
    reversedOn: string | null;
    status: INVOICE_STATUS;
    statusName: string;
    submittedOn: Date | null;
    type: INVOICE_TYPE;
    typeName: string;

    // @FE decoration, for easier operations
    invoiceDocumentAnnotated: undefined;
    payorInvoice: undefined;
    recipients: undefined;
    billingTimekeeperName: undefined;
}

export interface InvoicePayor {
    payor: InvoicePayorPayor;
    invoice: InvoicePayorInvoice;
    default: boolean;
}

export interface InvoiceActionSubmitParams {
    number: string;
    invoiceId: string;
    invoiceType: Invoice['type'];
    invoiceDate: Invoice['invoicedOn'];
    submitException: Invoice['submitException'];
    recipients: Invoice['recipients'];
    error: Invoice['error'];
    isThirdParty: Invoice['thirdParty'];
    client: Invoice['client'];
    payorInvoice: {
        payorId: string;
    } | null;
    billingTimekeeperName?: Invoice['billingTimekeeperName'];
    navigateToParent?: boolean;
    markAsSubmitted?: boolean;
    isTimekeeper?: boolean;
    defaultInvoiceSubmissionNote?: Invoice['defaultInvoiceSubmissionNote'];
    onSucess?: () => Promise<any> | any;
}

// Invoice actions
// This might not be the correct place for this.
// We will refactor this when we introduce i18n
export enum INVOICE_ACTION_WARNING {
    CANNOT_SUBMIT = 'CannotSubmit',
    CANNOT_SEND_FOR_REVIEW = 'CannotSendForReview',
    CANNOT_MARK_AS_SUBMITTED = 'CannotMarkAsSubmitted',
    CANNOT_COMPLETE_REVIEW = 'CannotCompleteReview'
}

export enum PERSONA {
    BILLING_TIMEKEEPER = 'BillingTimekeeper',
    COLLECTION_TIMEKEEEPER = 'CollectionTimekeeper',
    BILLER = 'Biller'
}

export interface BulkAction {
    canDisplay: boolean;
    canDo: boolean;
    warnings: INVOICE_ACTION_WARNING[];
}

export type PageKey =
    | 'pendingEPdf'
    | 'pendingPhysical'
    | 'pendingEBilling'
    | 'consolidated'
    | 'outstandingEPdf'
    | 'outstandingPhysical'
    | 'outstandingEBilling'
    | 'paid'
    | 'needsAttention'
    | 'unknown';

export enum InvoiceFilter {
    MY_INVOICES = 'myInvoices',
    CLIENTS = 'clientIds',
    BILLING_TIME_KEEPERS = 'billingTimekeeperIds',
    BILLERS = 'billerIds',
    STATUSES = 'statuses',
    PAID_ON_SINCE = 'paidOnSince',
    OVERDUE = 'overdue',
    NEEDS_SUBMISSSION_BY_TIMEKEEPER = 'needsSubmissionByTimekeeper',
    INVOICE_SUB_GROUP_STATUS = 'subGroupStatuses',
    WRITE_OFF_PENDING = 'writeOffPending',
    DUE_ON_BEFORE = 'dueOnBefore',
    INCLUDE_RELATED_CLIENTS = 'includeRelatedClients'
}

export type InvoiceStatusFilter = {
    [key in PageKey]: INVOICE_STATUS[];
};

export interface InvoiceFilters {
    [InvoiceFilter.BILLING_TIME_KEEPERS]: string[];
    [InvoiceFilter.BILLERS]: string[];
    [InvoiceFilter.CLIENTS]: string[];
    [InvoiceFilter.STATUSES]: INVOICE_STATUS[];
    [InvoiceFilter.PAID_ON_SINCE]: PAID_ON_SINCE[];
    [InvoiceFilter.OVERDUE]: string[];
    [InvoiceFilter.NEEDS_SUBMISSSION_BY_TIMEKEEPER]: string[];
    [InvoiceFilter.INVOICE_SUB_GROUP_STATUS]: string[];
    [InvoiceFilter.WRITE_OFF_PENDING]: string[];
    [InvoiceFilter.DUE_ON_BEFORE]: DUE_ON_BEFORE[];
    [InvoiceFilter.INCLUDE_RELATED_CLIENTS]: string[];
}

export enum PAID_ON_SINCE {
    LAST_30_DAYS = 'last30Days',
    LAST_3_MONTHS = 'last3Months',
    LAST_6_MONTHS = 'last6Months',
    LAST_12_MONTHS = 'last12Months'
}

export interface Currency {
    code: string;
    name: string | null;
    symbol: string;
}

export interface InvoiceForeignCurrency {
    invoiceAmount: number;
    outstandingBalance: number | null;
    paidAmount: number | null;
}

export enum DUE_ON_BEFORE {
    NEXT_7_DAYS = 'next7Days',
    NEXT_14_DAYS = 'next14Days',
    NEXT_20_DAYS = 'next20Days',
    NEXT_25_DAYS = 'next25Days',
    NEXT_30_DAYS = 'next30Days',
    OVERDUE = 'overdue'
}
