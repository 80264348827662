import apiClient from '../../shared/api/apiClient';
import {
    AgedAR,
    ARTotal,
    ClientMetricsWithClientIdentifier,
    CurrentMonthBillerSubmissions,
    InvoiceMetricsByStatus,
    InvoiceMetricsByYear,
    InvoiceMetricsTotals,
    MonthlyInvoiceOrPayment,
    PendingInvoiceByType,
    PendingInvoicesByBiller
} from '../models/dashboard';

const Charts = {
    BASE_URL: '/charts',
    getPendingInvoicesByBiller: () =>
        apiClient.get<PendingInvoicesByBiller[]>(`${Charts.BASE_URL}/pendinginvoicesbybiller`),
    getPendingInvoicesByType: () =>
        apiClient.get<PendingInvoiceByType[]>(`${Charts.BASE_URL}/pendinginvoicesbytype`),
    getPaymentsDataByMonth: () =>
        apiClient.get<MonthlyInvoiceOrPayment[]>(`${Charts.BASE_URL}/recentpaymentsbymonth`),
    getMonthlyInvoices: () =>
        apiClient.get<MonthlyInvoiceOrPayment[]>(`${Charts.BASE_URL}/invoicedbymonth`),
    getMonthlyBacklog: () =>
        apiClient.get<MonthlyInvoiceOrPayment[]>(`${Charts.BASE_URL}/backlogbymonth`),
    getARByTotal: () => apiClient.get<ARTotal>(`${Charts.BASE_URL}/arbytype`),
    getAgedAR: () => apiClient.get<AgedAR[]>(`${Charts.BASE_URL}/agedar`),
    getClientMetrics: (params: URLSearchParams) =>
        apiClient.get<ClientMetricsWithClientIdentifier[]>(`${Charts.BASE_URL}/clientmetrics`, {
            params
        }),
    getInvoiceMetricsByYear: () =>
        apiClient.get<InvoiceMetricsByYear[]>(`${Charts.BASE_URL}/invoicemetricsbyyear`),
    getInvoiceMetricsTotals: () =>
        apiClient.get<InvoiceMetricsTotals>(`${Charts.BASE_URL}/invoicemetricstotals`),
    getCurrentMonthBillerSubmissions: () =>
        apiClient.get<CurrentMonthBillerSubmissions[]>(
            `${Charts.BASE_URL}/currentmonthinvoicesubmissionsbybiller`
        ),
    getMyInvoiceMetricsByStatus: (params: URLSearchParams) =>
        apiClient.get<InvoiceMetricsByStatus>(`${Charts.BASE_URL}/myinvoicemetricsbystatus`, {
            params
        })
};

export default Charts;
